import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootState } from 'src/app/states/root.state';
import { LoadingState } from 'src/app/states/loading/loading.state';

/**
 * The APP loading component showed according the loading state
 */
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {

  /**
   * The loading state
   */
  loadingState$: Observable<LoadingState>;

  /**
   * @ignore
   *
   * @param store
   */
  constructor(
    private store: Store<RootState>
  ) { }

  /**
   * @ignore
   */
  ngOnInit() {
    this.loadingState$ = this.store.select('loading');
  }

}
