import { Predicate } from '@angular/core';
import { Update, EntityMapOne, EntityMap } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Project } from '../../models/project/Project';

export const loadProjects = createAction('[Projects/Entity] Load Projects', props<{ projects: Project[] }>());
export const setProjects = createAction('[Projects/Entity] Set Projects', props<{ projects: Project[] }>());
export const addProject = createAction('[Projects/Entity] Add Project', props<{ project: Project }>());
export const setProject = createAction('[Projects/Entity] Set Project', props<{ project: Project }>());
export const upsertProject = createAction('[Projects/Entity] Upsert Project', props<{ project: Project }>());
export const addProjects = createAction('[Projects/Entity] Add Projects', props<{ projects: Project[] }>());
export const upsertProjects = createAction('[Projects/Entity] Upsert Projects', props<{ projects: Project[] }>());
export const updateProject = createAction('[Projects/Entity] Update Project', props<{ update: Update<Project> }>());
export const updateProjects = createAction('[Projects/Entity] Update Projects', props<{ updates: Update<Project>[] }>());
export const mapProject = createAction('[Projects/Entity] Map Project', props<{ entityMap: EntityMapOne<Project> }>());
export const mapProjects = createAction('[Projects/Entity] Map Projects', props<{ entityMap: EntityMap<Project> }>());
export const deleteProject = createAction('[Projects/Entity] Delete Project', props<{ id: string }>());
export const deleteProjects = createAction('[Projects/Entity] Delete Projects', props<{ ids: string[] }>());
// eslint-disable-next-line max-len
export const deleteProjectsByPredicate = createAction('[Projects/Entity] Delete Projects By Predicate', props<{ predicate: Predicate<Project> }>());
export const clearProjects = createAction('[Projects/Entity] Clear Projects');


/**
 * The sync action
 */
export const syncProjects = createAction('[Projects/API] Sync', props<{ changed: string }>());

/**
 * The syncProjects success action
 */
export const syncProjectsSuccess = createAction('[Projects/API] Sync success', props<{ projects: Project[] }>());

/**
 * The syncProjects fail action
 */
export const syncProjectsFail = createAction('[Projects/API] Sync fail', props<{ error: any }>());
