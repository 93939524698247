import { Injectable } from '@angular/core';

@Injectable()
export class Utilities {

  static getParameterByName(name: string, url: string) {
    if (!url) { url = window.location.href; }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) { return null; }
    if (!results[2]) { return ''; }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  static async waitFor(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  static async asyncForEach(source, callback) {
    if (source instanceof Array) {
      for (let index = 0; index < source.length; index++) {
        await callback(source[index], index, source);
      }
    }
    else {
      for (const key in source) {
        if (source.hasOwnProperty(key)) {
          await callback(source[key], key, source);
        }
      }
    }
  }
}
