import { Predicate } from '@angular/core';
import { Update, EntityMapOne, EntityMap } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Utility } from '../../models/utility/Utility';

export const loadUtilities = createAction('[Utilities/Entity] Load Utilities', props<{ utilities: Utility[] }>());
export const setUtilities = createAction('[Utilities/Entity] Set Utilities', props<{ utilities: Utility[] }>());
export const addUtility = createAction('[Utilities/Entity] Add Document', props<{ utility: Utility }>());
export const setUtility = createAction('[Utilities/Entity] Set Document', props<{ utility: Utility }>());
export const upsertUtility = createAction('[Utilities/Entity] Upsert Document', props<{ utility: Utility }>());
export const addUtilities = createAction('[Utilities/Entity] Add Utilities', props<{ utilities: Utility[] }>());
export const upsertUtilities = createAction('[Utilities/Entity] Upsert Utilities', props<{ utilities: Utility[] }>());
export const updateUtility = createAction('[Utilities/Entity] Update Document', props<{ update: Update<Utility> }>());
export const updateUtilities = createAction('[Utilities/Entity] Update Utilities', props<{ updates: Update<Utility>[] }>());
export const mapUtility = createAction('[Utilities/Entity] Map Document', props<{ entityMap: EntityMapOne<Utility> }>());
export const mapUtilities = createAction('[Utilities/Entity] Map Utilities', props<{ entityMap: EntityMap<Utility> }>());
export const deleteUtility = createAction('[Utilities/Entity] Delete Document', props<{ id: string }>());
export const deleteUtilities = createAction('[Utilities/Entity] Delete Utilities', props<{ ids: string[] }>());
// eslint-disable-next-line max-len
export const deleteUtilitiesByPredicate = createAction('[Utilities/Entity] Delete Utilities By Predicate', props<{ predicate: Predicate<Utility> }>());
export const clearUtilities = createAction('[Utilities/Entity] Clear Utilities');


/**
 * The sync action
 */
export const syncUtilities = createAction('[Utilities/API] Sync Utilities');

/**
 * The syncUtilities success action
 */
export const syncUtilitiesSuccess = createAction('[Utilities/API] Sync Utilities success', props<{ utilities: Utility[] }>());

/**
 * The syncUtilities fail action
 */
export const syncUtilitiesFail = createAction('[Utilities/API] Sync Utilities fail', props<{ error: any }>());
