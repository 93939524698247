import { Token } from '../token/Token';

/**
 * The User model
 */
export class User {
  /**
   * The email used as credential to login
   */
  email = '';
  /**
   * The unique identifier of the user
   */
  id = '';
  /**
   * The name of the user
   */
  name = '';
  /**
   * The phone of the user
   */
  phone = '';
  /**
   * The company area where the user work
   */
  companyArea = '';
  /**
   * The position name of the user profession
   */
  positionName = '';
  /**
   * The company name where the user work
   */
  companyName = '';
  /**
   * The date of creation
   */
  createdAt = '';
  /**
   * The token of the current session
   */
  token: Token = new Token();
}
