import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public localStorage: Storage | null = null;

  constructor(
    public storage: Storage
  ) {
    this.init();
  }

  async init() {
    const storage = await this.storage.create();
    this.localStorage = storage;
  }

  // set a key/value
  async set(key: string, value: any): Promise<any> {
    try {
      await this.localStorage?.set(key, value);
      return true;
    } catch (reason) {
      console.error(reason);
      return false;
    }
  }

  // to get a key/value pair
  async get(key: string): Promise<any> {
    try {
      const result = await this.localStorage?.get(key);
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      console.error(reason);
      return null;
    }
  }

  // set a key/value object
  async setObject(key: string, object: Record<string, unknown>) {
    try {
      await this.storage.set(key, JSON.stringify(object));
      return true;
    } catch (reason) {
      console.error(reason);
      return false;
    }
  }

  // get a key/value object
  async getObject(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      if (result != null) {
        return JSON.parse(result);
      }
      return null;
    } catch (reason) {
      console.error(reason);
      return null;
    }
  }

  // remove a single key value:
  remove(key: string) {
    try {
      return this.storage.remove(key);
    } catch (reason) {
      console.error(reason);
      return null;
    }
  }

  // delete all data from your application:
  clear() {
    this.storage.clear();
  }
}
