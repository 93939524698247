import { Injectable } from '@angular/core';
import { Environment, Instance } from 'src/environments/environment.interface';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EnvironmentService implements Environment {

  constructor() { }

  get production() {
    return environment.production;
  }

  get enableDebugTools() {
    return environment.enableDebugTools;
  }

  get logLevel() {
    return environment.logLevel;
  }

  get version() {
    return environment.version;
  }

  get title() {
    return environment.title;
  }

  get usePKCE() {
    return environment.usePKCE;
  }

  get isMultiInstance() {
    return environment.isMultiInstance;
  }

  get instances() {
    return environment.instances;
  }

  get defaultInstance() {
    return environment.defaultInstance;
  }

  // get storage() {
  //   return environment.storage;
  // }

  // get auth() {
  //   return environment.auth;
  // }

  // get data() {
  //   return environment.data;
  // }

  getInstance(instanceName: string) {
    const instance = environment.instances.find(x => x.name === instanceName);
    return instance;
  }

}
