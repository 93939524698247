import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { loadingReducer } from './loading/loading.reducers';
import { LoginEffects } from './login/login.effects';
import { loginReducer } from './login/login.reducers';
import { networkReducer } from './network/network.reducers';
import { utilitiesReducer } from './utilities/utilities.reducers';
import { UtilitiesEffects } from './utilities/utilities.effects';
import { projectsReducer } from './projects/projects.reducers';
import { ProjectsEffects } from './projects/projects.effects';
import { tasksReducer } from './tasks/tasks.reducers';
import { TasksEffects } from './tasks/tasks.effects';
import { documentsReducer } from './documents/documents.reducers';
import { DocumentsEffects } from './documents/documents.effects';
import { localFilesReducer } from './local-files/local-files.reducers';
import { LocalFilesEffects } from './local-files/local-files.effects';
import { RootState } from './root.state';

import { localStorageSync } from 'ngrx-store-localstorage';

export const onSyncError = (err: any) => {
  console.log(err);
};

export const reducers: ActionReducerMap<RootState> = {
  network: networkReducer,
  login: loginReducer,
  loading: loadingReducer,
  utilities: utilitiesReducer,
  projects: projectsReducer,
  tasks: tasksReducer,
  documents: documentsReducer,
  localFiles: localFilesReducer
};

export const localStorageSyncReducer = (reducer: ActionReducer<any>): ActionReducer<any> => localStorageSync({
  keys: [                       // Only sync some states
    'login',
    'utilities',
    'projects',
    'tasks',
    'documents',
    'localFiles'
  ],
  rehydrate: true
})(reducer);
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

/**
 * An array of store reducers
 */
export const rootStateModule = [
  StoreModule.forRoot(reducers, {
    metaReducers
  }),
  StoreModule.forFeature('loading', loadingReducer),
  EffectsModule.forRoot([
  ]),
  EffectsModule.forFeature([
    LoginEffects,
    UtilitiesEffects,
    ProjectsEffects,
    TasksEffects,
    DocumentsEffects,
    LocalFilesEffects
  ])
];
