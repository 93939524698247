import { Action, createReducer, on } from '@ngrx/store';
import * as TasksActions from './tasks.actions';
import { tasksAdapter, TasksState } from './TasksState';

/**
 * The initial state for the tasks
 */
const initialState: TasksState = tasksAdapter.getInitialState({
  error: null,
  syncMessage: '',
  isSyncing: false,
  isSynced: false
});

/**
 * The reducer for the Tasks state
 */
const reducer = createReducer(
  initialState,
  on(TasksActions.addTask, (state, { task }) => tasksAdapter.addOne(task, state)),
  on(TasksActions.setTask, (state, { task }) => tasksAdapter.setOne(task, state)),
  on(TasksActions.upsertTask, (state, { task }) => tasksAdapter.upsertOne(task, state)),
  on(TasksActions.addTasks, (state, { tasks }) => tasksAdapter.addMany(tasks, state)),
  on(TasksActions.upsertTasks, (state, { tasks }) => tasksAdapter.upsertMany(tasks, state)),
  on(TasksActions.updateTask, (state, { update }) => tasksAdapter.updateOne(update, state)),
  on(TasksActions.updateTasks, (state, { updates }) => tasksAdapter.updateMany(updates, state)),
  on(TasksActions.mapTask, (state, { entityMap }) => tasksAdapter.mapOne(entityMap, state)),
  on(TasksActions.mapTasks, (state, { entityMap }) => tasksAdapter.map(entityMap, state)),
  on(TasksActions.deleteTask, (state, { id }) => tasksAdapter.removeOne(id, state)),
  on(TasksActions.deleteTasks, (state, { ids }) => tasksAdapter.removeMany(ids, state)),
  on(TasksActions.deleteTasksByPredicate, (state, { predicate }) => tasksAdapter.removeMany(predicate, state)),
  on(TasksActions.loadTasks, (state, { tasks }) => tasksAdapter.setAll(tasks, state)),
  on(TasksActions.setTasks, (state, { tasks }) => tasksAdapter.setMany(tasks, state)),
  on(TasksActions.clearTasks, state => tasksAdapter.removeAll({ ...state, selectedUserId: null })),

  on(TasksActions.toSync, (currentState) => ({
    ...currentState,
    error: null,
    syncMessage: '',
    isSyncing: false,
    isSynced: false
  })),
  on(TasksActions.synced, (currentState) => ({
    ...currentState,
    error: null,
    isSynced: true
  })),

  on(TasksActions.syncTasks, (currentState) => ({
    ...currentState,
    error: null,
    isSyncing: true
  })),
  on(TasksActions.syncTasksSuccess, (currentState, action) => ({
    ...currentState,
    isSyncing: false
  })),
  on(TasksActions.syncTasksFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isSyncing: false
  })),

  on(TasksActions.syncProjectTasks, (currentState, action) => ({
    ...currentState,
    error: null,
    syncMessage: action.projectId,
    isSyncing: true
  })),
  on(TasksActions.syncProjectTasksSuccess, (currentState, action) => ({
    ...currentState,
    isSyncing: false
  })),
  on(TasksActions.syncProjectTasksFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isSyncing: false
  })),

);

/**
 * The tasks reducer
 */
export const tasksReducer = (state: TasksState, action: Action) => reducer(state, action);
