import { Action, createReducer, on } from '@ngrx/store';
import * as DocumentsActions from './documents.actions';
import { documentsAdapter, DocumentsState } from './DocumentsState';

/**
 * The initial state for the documents
 */
const initialState: DocumentsState = documentsAdapter.getInitialState({
  error: null,
  isSyncing: false,
  isSynced: false
});

/**
 * The reducer for the Documents state
 */
const reducer = createReducer(
  initialState,
  on(DocumentsActions.addDocument, (state, { document }) => documentsAdapter.addOne(document, state)),
  on(DocumentsActions.setDocument, (state, { document }) => documentsAdapter.setOne(document, state)),
  on(DocumentsActions.upsertDocument, (state, { document }) => documentsAdapter.upsertOne(document, state)),
  on(DocumentsActions.addDocuments, (state, { documents }) => documentsAdapter.addMany(documents, state)),
  on(DocumentsActions.upsertDocuments, (state, { documents }) => documentsAdapter.upsertMany(documents, state)),
  on(DocumentsActions.updateDocument, (state, { update }) => documentsAdapter.updateOne(update, state)),
  on(DocumentsActions.updateDocuments, (state, { updates }) => documentsAdapter.updateMany(updates, state)),
  on(DocumentsActions.mapDocument, (state, { entityMap }) => documentsAdapter.mapOne(entityMap, state)),
  on(DocumentsActions.mapDocuments, (state, { entityMap }) => documentsAdapter.map(entityMap, state)),
  on(DocumentsActions.deleteDocument, (state, { id }) => documentsAdapter.removeOne(id, state)),
  on(DocumentsActions.deleteDocuments, (state, { ids }) => documentsAdapter.removeMany(ids, state)),
  on(DocumentsActions.deleteDocumentsByPredicate, (state, { predicate }) => documentsAdapter.removeMany(predicate, state)),
  on(DocumentsActions.loadDocuments, (state, { documents }) => documentsAdapter.setAll(documents, state)),
  on(DocumentsActions.setDocuments, (state, { documents }) => documentsAdapter.setMany(documents, state)),
  on(DocumentsActions.clearDocuments, state => documentsAdapter.removeAll({ ...state, selectedUserId: null })),

  on(DocumentsActions.syncDocuments, (currentState) => ({
    ...currentState,
    error: null,
    isSynced: false,
    isSyncing: true
  })),
  on(DocumentsActions.syncDocumentsSuccess, (currentState, action) => ({
    ...currentState,
    isSynced: true,
    isSyncing: false
  })),
  on(DocumentsActions.syncDocumentsFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isSynced: false,
    isSyncing: false
  })),

  on(DocumentsActions.syncProjectDocuments, (currentState, action) => ({
    ...currentState,
    error: null,
    isSynced: false,
    isSyncing: true
  })),
  on(DocumentsActions.syncProjectDocumentsSuccess, (currentState, action) => ({
    ...currentState,
    isSynced: true,
    isSyncing: false
  })),
  on(DocumentsActions.syncProjectDocumentsFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isSynced: false,
    isSyncing: false
  })),

);

/**
 * The documents reducer
 */
export const documentsReducer = (state: DocumentsState, action: Action) => reducer(state, action);
