import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import * as LocalFilesActions from './local-files.actions';
import * as LoginActions from '../login/login.actions';

@Injectable()
export class LocalFilesEffects {

  // Clear local files at logout
  logoutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LoginActions.logoutSuccess),
    map(() => LocalFilesActions.clearLocalFiles())
  ));

  constructor(
    private actions$: Actions
  ) { }
}
