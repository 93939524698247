import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Network } from '@capacitor/network';
import { Store } from '@ngrx/store';
import { RootState } from '../../states/root.state';
import * as NetworkActions from '../../states/network/network.actions';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(
    private platform: Platform,
    private store: Store<RootState>
  ) {
    this.platform.ready().then(() => {
      this.initializeNetwork();
    });
  }

  initializeNetwork() {
    try {
      Network.getStatus().then(status => {
        this.onConnectionChange(status.connectionType);
      });
      Network.addListener('networkStatusChange', status => {
        this.onConnectionChange(status.connectionType);
      });
    } catch (error) {
      console.error(error);
    }
  }

  onConnectionChange(connectionType: string) {
    this.store.dispatch(NetworkActions.connectionChange({
      connectionType
    }));
  }
}
