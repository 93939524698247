import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/models/user/User';

/**
 * The recover password reset action
 */
export const recoverPasswordReset = createAction('[Recover password] reset');

/**
 * The recover password action
 */
export const recoverPassword = createAction('[Recover password]', props<{ email: string; instance: string }>());

/**
 * The recover password success action
 */
export const recoverPasswordSuccess = createAction('[Recover password] success');

/**
 * The recover password fail action
 */
export const recoverPasswordFail = createAction('[Recover password] fail', props<{ error: Error }>());

/**
 * The check email action
 */
export const checkEmail = createAction('[Check email]', props<{ email: string; instance: string }>());

/**
 * The check email success action
 */
export const checkEmailSuccess = createAction('[Check email] success');

/**
 * The check email fail action
 */
export const checkEmailFail = createAction('[Check email] fail', props<{ error: Error }>());

/**
 * The check code action
 */
export const checkCode = createAction('[Check code]', props<{ email: string; code: string; instance: string }>());

/**
 * The check code success action
 */
export const checkCodeSuccess = createAction('[Check code] success');

/**
 * The check code fail action
 */
export const checkCodeFail = createAction('[Check code] fail', props<{ error: Error }>());

/**
 * The check code action
 */
export const updatePassword = createAction('[Update password]', props<{
  email: string; code: string; password: string; instance: string;
}>());

/**
 * The check code success action
 */
export const updatePasswordSuccess = createAction('[Update password] success');

/**
 * The check code fail action
 */
export const updatePasswordFail = createAction('[Update password] fail', props<{ error: Error }>());

/**
 * The authorize request action
 */
export const authorize = createAction('[Authorize]', props<{ instance: string; prompt: string }>());

/**
 * The authorize request success action
 */
export const authorizeSuccess = createAction('[Authorize] success', props<{
  state: string; codeVerifier: string; codeChallenge: string;
}>());

/**
 * The authorize request fail action
 */
export const authorizeFail = createAction('[Authorize] fail', props<{ error: Error }>());

/**
 * The convertCode request action
 */
export const convertCode = createAction('[ConvertCode]', props<{ code: string }>());

/**
 * The convertCode request success action
 */
export const convertCodeSuccess = createAction('[ConvertCode] success', props<{ user: User }>());

/**
 * The convertCode request fail action
 */
export const convertCodeFail = createAction('[ConvertCode] fail', props<{ error: Error }>());

/**
 * The login action
 */
export const login = createAction('[Login]', props<{ email: string; password: string; persistent: boolean; instance: string }>());

/**
 * The login success action
 */
export const loginSuccess = createAction('[Login] success', props<{ user: User }>());

/**
 * The login fail action
 */
export const loginFail = createAction('[Login] fail', props<{ error: Error }>());

/**
 * The update user success action
 */
export const updateUserSuccess = createAction('[UpdateUser] success', props<{ user: any }>());

/**
 * The update user fail action
 */
export const updateUserFail = createAction('[updateUser] fail', props<{ error: Error }>());

/**
 * The refresh token action
 */
export const refreshToken = createAction('[RefreshToken]', props<{ refreshToken: string }>());

/**
 * The refresh token success action
 */
export const refreshTokenSuccess = createAction('[RefreshToken] success', props<{ user: User }>());

/**
 * The refresh token fail action
 */
export const refreshTokenFail = createAction('[RefreshToken] fail', props<{ error: Error }>());

/**
 * The logout action
 */
export const logout = createAction('[Logout]');

/**
 * The logout success action
 */
export const logoutSuccess = createAction('[Logout] success');

/**
 * The logout fail action
 */
export const logoutFail = createAction('[Logout] fail', props<{ error: Error }>());

/**
 * The soft logout action
 */
export const softLogout = createAction('[SoftLogout]');

/**
 * The soft logout success action
 */
export const softLogoutSuccess = createAction('[SoftLogout] success');

/**
 * The soft logout fail action
 */
export const softLogoutFail = createAction('[SoftLogout] fail', props<{ error: Error }>());
