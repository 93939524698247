import { Action, createReducer, on } from '@ngrx/store';
import { rootInitialState } from '../root.initial.state';
// eslint-disable-next-line max-len
import { authorize, authorizeFail, authorizeSuccess, checkCode, checkCodeFail, checkCodeSuccess, checkEmail, checkEmailFail, checkEmailSuccess, convertCode, convertCodeFail, convertCodeSuccess, login, loginFail, loginSuccess, logout, logoutFail, logoutSuccess, recoverPassword, recoverPasswordFail, recoverPasswordReset, recoverPasswordSuccess, refreshToken, refreshTokenFail, refreshTokenSuccess, softLogout, softLogoutFail, softLogoutSuccess, updatePassword, updatePasswordFail, updatePasswordSuccess, updateUserSuccess } from './login.actions';
import { LoginState } from './login.state';

/**
 * The initial state for the login
 */
const initialState: LoginState = rootInitialState.login;

/**
 * The reducer for the login state
 */
const reducer = createReducer(
  initialState,
  on(recoverPassword, (currentState, action) => ({
    ...currentState,
    error: null,
    instance: action.instance,
    isValidatingEmail: false,
    isValidatedEmail: false,
    isValidatingOTP: false,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: true,
    recoverPassword: {
      email: action.email,
      code: ''
    }
  })),
  on(recoverPasswordSuccess, (currentState) => ({
    ...currentState,
    error: null,
    isValidatedEmail: false,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: false
  })),
  on(recoverPasswordFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isValidatedEmail: false,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: false
  })),
  on(recoverPasswordReset, (currentState) => ({
    ...currentState,
    error: null,
    isValidatedEmail: false,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: false
  })),
  on(checkEmail, (currentState, action) => ({
    ...currentState,
    error: null,
    instance: action.instance,
    isValidatingEmail: true,
    isValidatedEmail: false,
    isValidatingOTP: false,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: true,
    recoverPassword: {
      email: action.email,
      code: ''
    }
  })),
  on(checkEmailSuccess, (currentState) => ({
    ...currentState,
    error: null,
    isValidatingEmail: false,
    isValidatedEmail: true,
    isValidatingOTP: false,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: true
  })),
  on(checkEmailFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isValidatedEmail: false,
    isValidatingOTP: false,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: true
  })),
  on(checkCode, (currentState, action) => ({
    ...currentState,
    error: null,
    instance: action.instance,
    isValidatingEmail: false,
    isValidatingOTP: true,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: true,
    recoverPassword: {
      email: action.email,
      code: action.code
    }
  })),
  on(checkCodeSuccess, (currentState) => ({
    ...currentState,
    error: null,
    isValidatingOTP: false,
    isValidatedOTP: true,
    isRecoveredPassword: false,
    isRecoveringPassword: true
  })),
  on(checkCodeFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: true
  })),
  on(updatePassword, (currentState, action) => ({
    ...currentState,
    error: null,
    instance: action.instance,
    isValidatingOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: true,
    recoverPassword: {
      email: action.email,
      code: action.code
    }
  })),
  on(updatePasswordSuccess, (currentState) => ({
    ...currentState,
    error: null,
    isRecoveredPassword: true,
    isRecoveringPassword: false,
    recoverPassword: null
  })),
  on(updatePasswordFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isRecoveredPassword: false,
    isRecoveringPassword: true
  })),

  on(authorize, (currentState, action) => ({
    ...currentState,
    error: null,
    isValidatingEmail: false,
    isValidatedEmail: false,
    isValidatingOTP: false,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: false,
    isLoggedIn: false,
    isLoggingIn: true,
    pkceState: null,
    pkceCodeVerifier: null,
    pkceCodeChallenge: null,
    pkceCode: null,
    instance: action.instance,
    persistent: true,
    recoverPassword: null
  })),
  on(authorizeSuccess, (currentState, action) => ({
    ...currentState,
    pkceState: action.state,
    pkceCodeVerifier: action.codeVerifier,
    pkceCodeChallenge: action.codeChallenge,
  })),
  on(authorizeFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isLoggedIn: false,
    isLoggingIn: false,
    instance: null
  })),

  on(convertCode, (currentState, action) => ({
    ...currentState,
    pkceCode: action.code,
  })),
  on(convertCodeSuccess, (currentState, action) => ({
    ...currentState,
    pkceState: null,
    pkceCodeVerifier: null,
    pkceCodeChallenge: null,
    pkceCode: null,
    isLoggedIn: true,
    isLoggingIn: false,
    user: action.user
  })),
  on(convertCodeFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isLoggedIn: false,
    isLoggingIn: false,
    instance: null
  })),

  on(login, (currentState, action) => ({
    ...currentState,
    error: null,
    isValidatingEmail: false,
    isValidatedEmail: false,
    isValidatingOTP: false,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: false,
    isLoggedIn: false,
    isLoggingIn: true,
    pkceState: null,
    pkceCodeVerifier: null,
    pkceCodeChallenge: null,
    pkceCode: null,
    instance: action.instance,
    persistent: action.persistent,
    recoverPassword: null
  })),
  on(loginSuccess, (currentState, action) => ({
    ...currentState,
    isLoggedIn: true,
    isLoggingIn: false,
    user: action.user
  })),
  on(loginFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isLoggedIn: false,
    isLoggingIn: false,
    instance: null
  })),
  on(updateUserSuccess, (currentState, action) => ({
    ...currentState,
    user: {
      ...currentState.user,
      id: action.user.id,
      email: action.user.email,
      name: action.user.name,
      phone: action.user.phone,
      companyArea: action.user.companyArea,
      positionName: action.user.positionName,
      companyName: action.user.companyName,
      createdAt: action.user.createdAt
    }
  })),
  on(refreshToken, (currentState) => ({
    ...currentState,
    error: null,
    isLoggedIn: false,
    isLoggingIn: true
  })),
  on(refreshTokenSuccess, (currentState, action) => ({
    ...currentState,
    isLoggedIn: true,
    isLoggingIn: false,
    user: {
      ...currentState.user,
      token: action.user?.token
    }
  })),
  on(refreshTokenFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isLoggedIn: false,
    isLoggingIn: false,
    isLoggingOut: false,
    user: null
  })),
  on(logout, (currentState) => ({
    ...currentState,
    error: null,
    isLoggingOut: true
  })),
  on(logoutSuccess, (currentState) => ({
    ...currentState,
    error: null,
    isValidatingEmail: false,
    isValidatedEmail: false,
    isValidatingOTP: false,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: false,
    isLoggedIn: false,
    isLoggingIn: false,
    isLoggingOut: false,
    pkceState: null,
    pkceCodeVerifier: null,
    pkceCodeChallenge: null,
    pkceCode: null,
    instance: null,
    persistent: false,
    user: null,
    recoverPassword: null
  })),
  on(logoutFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isValidatingEmail: false,
    isValidatedEmail: false,
    isValidatingOTP: false,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: false,
    isLoggedIn: false,
    isLoggingOut: false,
    recoverPassword: null
  })),
  on(softLogout, (currentState) => ({
    ...currentState,
    error: null,
    isLoggingOut: true
  })),
  on(softLogoutSuccess, (currentState) => ({
    ...currentState,
    error: null,
    isLoggedIn: false,
    isLoggingIn: false,
    isLoggingOut: false,
    pkceState: null,
    pkceCodeVerifier: null,
    pkceCodeChallenge: null,
    pkceCode: null,
    user: {
      ...currentState.user,
      token: {
        ...currentState.user.token,
        accessToken: null
      }
    }
  })),
  on(softLogoutFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isLoggingOut: false,
  }))
);

/**
 * The login reducer
 */
export const loginReducer = (state: LoginState, action: Action) => reducer(state, action);
