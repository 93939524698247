import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Task } from '../../models/task/Task';

/**
 * The interface of the tasks state
 */
export interface TasksState extends EntityState<Task> {
  error: any;
  syncMessage: string;
  isSyncing: boolean;
  isSynced: boolean;
}

export const selectTaskId = ((a: Task): string => a.id);

export const sortByStatus = (a: Task, b: Task): number => ((a.status) ? a.status.localeCompare(b.status) : -1);

export const tasksAdapter: EntityAdapter<Task> = createEntityAdapter<Task>({
  selectId: selectTaskId,
  sortComparer: sortByStatus,
});

export const getTasksState = createFeatureSelector<TasksState>('tasks');
export const {
  selectIds: taskIds,
  selectEntities: taskEntities,
  selectAll: tasks,
  selectTotal: totalTasks
} = tasksAdapter.getSelectors(getTasksState);

// select the array of task ids
export const selectTaskIds = taskIds;

// select the dictionary of task entities
export const selectTaskEntities = taskEntities;

// select the array of tasks
export const selectAllTasks = tasks;

// select the total task count
export const selectTaskTotal = totalTasks;

export const selectTask = (id: string) => createSelector(
  taskEntities,
  entities => entities[id]
);

export const selectTasksByID = (ids: string[]) => createSelector(
  taskEntities,
  entities => ids.map(id => entities[id])
);

export const selectRootTasksByProjectID = (id: string) => createSelector(
  selectAllTasks,
  (allTasks) => allTasks.filter(task => ((('' + task.projectId) === ('' + id))) && (null === task.parent)).filter(task => (!task.trashed))
);

const findDescendants = (objects: Task[], id: string, descendants: Task[], maxDepth: number, currentDepth = 0) => {
  if (currentDepth >= maxDepth) {
    return descendants;
  }

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < objects.length; i++) {
    if (((null === id) && (null === objects[i].parent)) || (('' + objects[i].parent) === ('' + id))) {
      descendants.push(objects[i]);
      findDescendants(objects, objects[i].id, descendants, maxDepth, currentDepth + 1);
    }
  }
  return descendants;
};

export const selectAllMilestonesByProjectID = (id: string, maxDepth: number) => createSelector(
  selectAllTasks,
  (allTasks) => findDescendants(
    allTasks.filter(task => (('' + task.projectId) === ('' + id))),
    null,
    [],
    maxDepth
  ).filter(item => ('project' === item.type)).filter(task => (!task.trashed))
);

export const selectAllTasksByProjectID = (id: string, maxDepth: number) => createSelector(
  selectAllTasks,
  (allTasks) => findDescendants(
    allTasks.filter(task => (('' + task.projectId) === ('' + id))),
    null,
    [],
    maxDepth
  ).filter(item => ('task' === item.type)).filter(task => (!task.trashed))
);

export const selectAllTasksByParentID = (id: string, maxDepth: number) => createSelector(
  selectAllTasks,
  (allTasks) => findDescendants(
    allTasks,
    id,
    [],
    maxDepth
  ).filter(item => ('task' === item.type)).filter(task => (!task.trashed))
);
