<div class="backdrop" *ngIf="( loadingState$ | async ).show">
  <div class="loader ion-text-center">
    <ion-card>
      <ion-card-header>
        <ion-card-title>
          <ion-spinner name="crescent" size="20"></ion-spinner>
        </ion-card-title>
        <ion-card-subtitle *ngIf="( loadingState$ | async ).context.title"
          [innerHtml]="( loadingState$ | async ).context.title"></ion-card-subtitle>
      </ion-card-header>

      <ion-card-content class="loading-message" *ngIf="( loadingState$ | async ).context.description"
        [innerHtml]="( loadingState$ | async ).context.description">
        <!-- <ion-text *ngIf="( loadingState$ | async ).message" [innerHtml]="( loadingState$ | async ).message"></ion-text> -->
      </ion-card-content>
    </ion-card>
  </div>

  <!-- <div class="loader ion-text-center">
    <ion-spinner name="crescent" size="20"></ion-spinner>
    <ion-text *ngIf="( loadingState$ | async ).message" [innerHtml]="( loadingState$ | async ).message"></ion-text>
  </div> -->
</div>