import { RootState } from 'src/app/states/root.state';

/**
 * All APP root initial states
 */
export const rootInitialState: RootState = {
  loading: {
    show: false,
    context: {
      title: '',
      description: ''
    }
  },
  network: {
    connected: false,
    connectionType: 'unknown'
  },
  login: {
    error: null,
    isValidatingEmail: false,
    isValidatedEmail: false,
    isValidatingOTP: false,
    isValidatedOTP: false,
    isRecoveredPassword: false,
    isRecoveringPassword: false,
    isLoggedIn: false,
    isLoggingIn: false,
    isLoggingOut: false,
    pkceState: null,
    pkceCodeVerifier: null,
    pkceCodeChallenge: null,
    pkceCode: null,
    instance: null,
    persistent: false,
    user: null,
    recoverPassword: null
  },
  utilities: {
    error: null,
    isSyncing: false,
    isSynced: false,
    ids: null,
    entities: null
  },
  projects: {
    error: null,
    syncMessage: '',
    isSyncing: false,
    isSynced: false,
    ids: null,
    entities: null
  },
  tasks: {
    error: null,
    syncMessage: '',
    isSyncing: false,
    isSynced: false,
    ids: null,
    entities: null
  },
  documents: {
    error: null,
    isSyncing: false,
    isSynced: false,
    ids: null,
    entities: null
  },
  localFiles: {
    error: null,
    ids: null,
    entities: null
  }
};
