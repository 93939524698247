import { Action, createReducer, on } from '@ngrx/store';
import * as LocalFilesActions from './local-files.actions';
import { localFilesAdapter, LocalFilesState } from './LocalFilesState';

/**
 * The initial state for the tasks
 */
const initialState: LocalFilesState = localFilesAdapter.getInitialState({
  error: null,
  isSyncing: false,
  isSynced: false
});

/**
 * The reducer for the LocalFile state
 */
const reducer = createReducer(
  initialState,
  on(LocalFilesActions.addLocalFile, (state, { localFile }) => localFilesAdapter.addOne(localFile, state)),
  on(LocalFilesActions.setLocalFile, (state, { localFile }) => localFilesAdapter.setOne(localFile, state)),
  on(LocalFilesActions.upsertLocalFile, (state, { localFile }) => localFilesAdapter.upsertOne(localFile, state)),
  on(LocalFilesActions.addLocalFiles, (state, { localFiles }) => localFilesAdapter.addMany(localFiles, state)),
  on(LocalFilesActions.upsertLocalFiles, (state, { localFiles }) => localFilesAdapter.upsertMany(localFiles, state)),
  on(LocalFilesActions.updateLocalFile, (state, { update }) => localFilesAdapter.updateOne(update, state)),
  on(LocalFilesActions.updateLocalFiles, (state, { updates }) => localFilesAdapter.updateMany(updates, state)),
  on(LocalFilesActions.mapLocalFile, (state, { entityMap }) => localFilesAdapter.mapOne(entityMap, state)),
  on(LocalFilesActions.mapLocalFiles, (state, { entityMap }) => localFilesAdapter.map(entityMap, state)),
  on(LocalFilesActions.deleteLocalFile, (state, { id }) => localFilesAdapter.removeOne(id, state)),
  on(LocalFilesActions.deleteLocalFiles, (state, { ids }) => localFilesAdapter.removeMany(ids, state)),
  on(LocalFilesActions.deleteLocalFilesByPredicate, (state, { predicate }) => localFilesAdapter.removeMany(predicate, state)),
  on(LocalFilesActions.loadLocalFiles, (state, { localFiles }) => localFilesAdapter.setAll(localFiles, state)),
  on(LocalFilesActions.setLocalFiles, (state, { localFiles }) => localFilesAdapter.setMany(localFiles, state)),
  on(LocalFilesActions.clearLocalFiles, state => localFilesAdapter.removeAll({ ...state, selectedUserId: null })),

);

/**
 * The tasks reducer
 */
export const localFilesReducer = (state: LocalFilesState, action: Action) => reducer(state, action);
