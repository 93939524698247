import { createAction, props } from '@ngrx/store';

/**
 * The show loading action
 */
export const show = createAction('[Loading] show', props<{ context: { title: string; description: string } }>());

/**
 * The hide loading action
 */
export const hide = createAction('[Loading] hide');
