import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Document } from '../../models/document/Document';

/**
 * The interface of the tasks state
 */
export interface DocumentsState extends EntityState<Document> {
  error: any;
  isSyncing: boolean;
  isSynced: boolean;
}

export const selectDocumentId = ((a: Document): string => a.id);

export const sortByRevisionIndex = (a: Document, b: Document): number => a.revisionIndex - b.revisionIndex;

export const documentsAdapter: EntityAdapter<Document> = createEntityAdapter<Document>({
  selectId: selectDocumentId,
  sortComparer: sortByRevisionIndex,
});

export const getDocumentsState = createFeatureSelector<DocumentsState>('documents');
export const {
  selectIds: documentIds,
  selectEntities: documentEntities,
  selectAll: documents,
  selectTotal: totalDocuments
} = documentsAdapter.getSelectors(getDocumentsState);

// select the array of document ids
export const selectDocumentIds = documentIds;

// select the dictionary of document entities
export const selectDocumentEntities = documentEntities;

// select the array of documents
export const selectAllDocuments = documents;

// select the total document count
export const selectDocumentTotal = totalDocuments;

export const selectDocument = (id: string) => createSelector(
  documentEntities,
  entities => entities[id]
);

export const selectDocumentsByID = (ids: string[]) => createSelector(
  documentEntities,
  entities => ids.map(id => entities[id])
);
