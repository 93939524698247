/**
 * The Task model
 */
export class Task {
  /**
   * The id
   */
  id: string;
  /**
   * The project id
   */
  projectId?: string;
  /**
   * The project name
   */
  projectName?: string;
  /**
   * The name
   */
  name: string;
  /**
   * The type
   */
  type: string;
  /**
   * The status
   */
  status: string;
  /**
   * The parent
   */
  parent: string;
  /**
   * The internalDocId
   */
  internalDocId: string;

  /**
   * The document IDs
   */
  documents: {
    id: string;
    type: string;
  }[];

  /**
   * The link IDs
   */
  links: {
    id: string;
    type: string;
  }[];

  standardDocuments: {
    id: string;
    type: string;
    internalDocId: string;
    name: string;
    revision: string;
    chapterCode: string;
    fileLink: string;
    smallFileLink: string;
  }[];

  planningIssuerPosition: string;
  planningIssueDeadlineDate: string;
  planningApproverPosition: string;
  planningApprovalDeadlineDate: string;

  visibilitySupplier: boolean;
  visibilityOwner: boolean;
  visibilityCertifier: boolean;

  supplierCompanies: { name: string; pivot: any }[];
  supplierCompaniesString: string;

  certifierCompanies: { name: string; pivot: any }[];
  certifierCompaniesString: string;

  /**
   * The last edit time
   */
  updatedAt: string;
  trashed: boolean;
}
