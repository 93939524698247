import { Predicate } from '@angular/core';
import { Update, EntityMapOne, EntityMap } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Task } from '../../models/task/Task';

export const loadTasks = createAction('[Tasks/Entity] Load Tasks', props<{ tasks: Task[] }>());
export const setTasks = createAction('[Tasks/Entity] Set Tasks', props<{ tasks: Task[] }>());
export const addTask = createAction('[Tasks/Entity] Add Task', props<{ task: Task }>());
export const setTask = createAction('[Tasks/Entity] Set Task', props<{ task: Task }>());
export const upsertTask = createAction('[Tasks/Entity] Upsert Task', props<{ task: Task }>());
export const addTasks = createAction('[Tasks/Entity] Add Tasks', props<{ tasks: Task[] }>());
export const upsertTasks = createAction('[Tasks/Entity] Upsert Tasks', props<{ tasks: Task[] }>());
export const updateTask = createAction('[Tasks/Entity] Update Task', props<{ update: Update<Task> }>());
export const updateTasks = createAction('[Tasks/Entity] Update Tasks', props<{ updates: Update<Task>[] }>());
export const mapTask = createAction('[Tasks/Entity] Map Task', props<{ entityMap: EntityMapOne<Task> }>());
export const mapTasks = createAction('[Tasks/Entity] Map Tasks', props<{ entityMap: EntityMap<Task> }>());
export const deleteTask = createAction('[Tasks/Entity] Delete Task', props<{ id: string }>());
export const deleteTasks = createAction('[Tasks/Entity] Delete Tasks', props<{ ids: string[] }>());
export const deleteTasksByPredicate = createAction('[Tasks/Entity] Delete Tasks By Predicate', props<{ predicate: Predicate<Task> }>());
export const clearTasks = createAction('[Tasks/Entity] Clear Tasks');


/**
 * The mark to sync action
 */
export const toSync = createAction('[Tasks/API] To sync tasks');

/**
 * The mark to synced action
 */
export const synced = createAction('[Tasks/API] Tasks synced');


/**
 * The sync action
 */
export const syncTasks = createAction('[Tasks/API] Sync tasks');

/**
 * The syncTasks success action
 */
export const syncTasksSuccess = createAction('[Tasks/API] Sync tasks success', props<{ tasks: Task[] }>());

/**
 * The syncTasks fail action
 */
export const syncTasksFail = createAction('[Tasks/API] Sync tasks fail', props<{ error: any }>());

/**
 * The sync project tasks action
 */
export const syncProjectTasks = createAction('[Tasks/API] Sync project tasks', props<{ projectId: string; changed: string }>());

/**
 * The sync Project Tasks success action
 */
export const syncProjectTasksSuccess = createAction('[Tasks/API] Sync project tasks success', props<{ tasks: Task[] }>());

/**
 * The sync Project Tasks fail action
 */
export const syncProjectTasksFail = createAction('[Tasks/API] Sync project tasks fail', props<{ error: any }>());
