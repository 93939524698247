import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocalFile } from '../../models/local-file/LocalFile';

/**
 * The interface of the local files state
 */
export interface LocalFilesState extends EntityState<LocalFile> {
  error: any;
}

export const selectLocalFileId = ((a: LocalFile): string => a.id);

export const sortByLastDownload = (a: LocalFile, b: LocalFile): number => a.lastDownload.localeCompare(b.lastDownload);

export const localFilesAdapter: EntityAdapter<LocalFile> = createEntityAdapter<LocalFile>({
  selectId: selectLocalFileId,
  sortComparer: sortByLastDownload,
});

export const getLocalFilesState = createFeatureSelector<LocalFilesState>('localFiles');
export const {
  selectIds: localFileIds,
  selectEntities: localFileEntities,
  selectAll: localFiles,
  selectTotal: totalLocalFiles
} = localFilesAdapter.getSelectors(getLocalFilesState);

// select the array of local file ids
export const selectLocalFileIds = localFileIds;

// select the dictionary of local file entities
export const selectLocalFileEntities = localFileEntities;

// select the array of local files
export const selectLocalFiles = localFiles;

// select the total local file count
export const selectLocalFileTotal = totalLocalFiles;

export const selectLocalFile = (id: string) => createSelector(
  localFileEntities,
  entities => entities[id]
);

export const selectLocalFilesByID = (ids: string[]) => createSelector(
  localFileEntities,
  entities => ids.map(id => entities[id])
);
