import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Project } from '../../models/project/Project';
import { DataService } from '../../services/data/data.service';
import * as ProjectsActions from './projects.actions';
import * as LoginActions from '../login/login.actions';
import * as TasksActions from '../tasks/tasks.actions';

@Injectable()
export class ProjectsEffects {

  syncProjects$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectsActions.syncProjects),
    switchMap((payload: { changed: string }) => this.dataService.getProjects(payload.changed).pipe(
      map((projects) => ProjectsActions.syncProjectsSuccess({ projects })),
      catchError(error => of(ProjectsActions.syncProjectsFail({ error })))
    ))
  ));

  syncProjectsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectsActions.syncProjectsSuccess),
    map((payload: { projects: Project[] }) => ProjectsActions.upsertProjects(payload))
  ));

  syncProjectsFail$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectsActions.syncProjectsFail),
    map(() => LoginActions.softLogout())
  ));

  syncProjectTasks$ = createEffect(() => this.actions$.pipe(
    ofType(TasksActions.syncProjectTasks),
    switchMap((payload: { projectId: string }) => this.dataService.setProjectLastSync(payload.projectId).pipe(
      map((project) => ProjectsActions.upsertProject({ project }))
    ))
  ));

  // Clear projects at logout
  logoutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LoginActions.logoutSuccess),
    map(() => ProjectsActions.clearProjects())
  ));

  // Clear projects at soft logout
  softLogoutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LoginActions.softLogoutSuccess),
    map(() => ProjectsActions.clearProjects())
  ));

  constructor(
    private actions$: Actions,
    private dataService: DataService
  ) { }
}
