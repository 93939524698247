import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from 'src/app/models/user/User';
import { Token } from 'src/app/models/token/Token';
import { EnvironmentService } from '../environment/environment.service';
import { RootState } from 'src/app/states/root.state';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';
import { Preferences } from '@capacitor/preferences';
import * as CryptoJS from 'crypto-js';
import { Browser } from '@capacitor/browser';
import { Platform } from '@ionic/angular';
import * as LoadingActions from '../../states/loading/loading.actions';
import * as LoginActions from '../../states/login/login.actions';
import { Utilities } from 'src/app/commons/utilities.class';

/**
 * A service to manage the authentication in APP
 *
 * This is a service that manages the authentication for an application.
 * It makes use of the Store, HttpClient, and EnvironmentService.
 * The service has methods for building the authorization base URL, client ID, client secret, and scope.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isMultiInstance: boolean | null = null;
  storagePrefix: string | null = null;
  authSystem: string | null = null;
  authBaseUrl: string | null = null;
  authClientId: string | null = null;
  authClientSecret: string | null = null;
  authScope: string | null = null;
  authRedirectUri: string | null = null;
  authDevice = 'default';
  authObject = '';

  /**
   * The contructor
   *
   * @param store
   * @param httpClient
   * @param environment
   */
  constructor(
    private store: Store<RootState>,
    private httpClient: HttpClient,
    private environment: EnvironmentService,
    private storageService: StorageService,
    private platform: Platform
  ) {
    this.authObject = (platform.is('capacitor')) ? 'mobileAuth' : 'webAuth';
    this.isMultiInstance = environment.isMultiInstance;
    const instance = environment.getInstance(environment.defaultInstance);
    this.storagePrefix = (!this.isMultiInstance && instance) ? instance.storage.prefix : null;
    this.authSystem = (!this.isMultiInstance && instance) ? instance[this.authObject].system : null;
    this.authBaseUrl = (!this.isMultiInstance && instance) ? instance[this.authObject].baseUrl : null;
    this.authClientId = (!this.isMultiInstance && instance) ? instance[this.authObject].clientId : null;
    this.authClientSecret = (!this.isMultiInstance && instance) ? instance[this.authObject].clientSecret : null;
    this.authScope = (!this.isMultiInstance && instance) ? instance[this.authObject].scope : null;
    this.authRedirectUri = (!this.isMultiInstance && instance) ? instance[this.authObject].redirectUri : null;
  }

  /**
   * Returns the instance of the application,
   * either the forced instance passed as a parameter or the instance stored in the state.
   *
   * @param forcedInstance
   * The instance forced to use
   *
   * @returns
   * An observable
   */
  getInstance(forcedInstance: string | null = null): Observable<string> {
    if (forcedInstance && forcedInstance.length) {
      return of(forcedInstance);
    }

    return this.store.select('login').pipe(
      take(1),
      switchMap(loginState => {
        if (loginState?.instance) {
          return of(loginState.instance);
        }
        return of('');
      })
    );
  }

  /**
   * Make use of getInstance to retrieve the instance and then use it to get the system authentication parameter from the environment.
   *
   * @param forcedInstance
   * The instance forced to use
   *
   * @returns
   * A promise
   */
  public buildAuthSystem(forcedInstance: string | null = null): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance(forcedInstance).pipe(take(1)).subscribe((instance) => {
          const currentInstance = (instance && instance.length) ? instance : this.environment.defaultInstance;
          let authSystem = '';
          const instanceParams = this.environment.getInstance(currentInstance);
          if (instanceParams) {
            authSystem = instanceParams[this.authObject].system;
          }
          resolve(authSystem);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  /**
   * Make use of getInstance to retrieve the instance and then use it to get the baseUrl authentication parameter from the environment.
   *
   * @param forcedInstance
   * The instance forced to use
   *
   * @returns
   * A promise
   */
  public buildAuthBaseUrl(forcedInstance: string | null = null): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance(forcedInstance).pipe(take(1)).subscribe((instance) => {
          const currentInstance = (instance && instance.length) ? instance : this.environment.defaultInstance;
          let authBaseUrl = '';
          const instanceParams = this.environment.getInstance(currentInstance);
          if (instanceParams) {
            authBaseUrl = instanceParams[this.authObject].baseUrl;
          }
          resolve(authBaseUrl);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  /**
   * Make use of getInstance to retrieve the instance and then use it to get the baseUrl authentication parameter from the environment.
   *
   * @param forcedInstance
   * The instance forced to use
   *
   * @returns
   * A promise
   */
  public buildDataBaseUrl(forcedInstance: string | null = null): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance(forcedInstance).pipe(take(1)).subscribe((instance) => {
          const currentInstance = (instance && instance.length) ? instance : this.environment.defaultInstance;
          let dataBaseUrl = '';
          const instanceParams = this.environment.getInstance(currentInstance);
          if (instanceParams) {
            dataBaseUrl = instanceParams.data.baseUrl;
          }
          resolve(dataBaseUrl);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  /**
   * Make use of getInstance to retrieve the instance and then use it to get the clientId authentication parameter from the environment.
   *
   * @param forcedInstance
   * The instance forced to use
   *
   * @returns
   * A promise
   */
  public buildAuthClientId(forcedInstance: string | null = null): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance(forcedInstance).pipe(take(1)).subscribe((instance) => {
          const currentInstance = (instance && instance.length) ? instance : this.environment.defaultInstance;
          let authClientId = '';
          const instanceParams = this.environment.getInstance(currentInstance);
          if (instanceParams) {
            authClientId = instanceParams[this.authObject].clientId;
          }
          resolve(authClientId);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  /**
   * Make use of getInstance to retrieve the instance and then use it to get the clientSecret authentication parameter from the environment.
   *
   * @param forcedInstance
   * The instance forced to use
   *
   * @returns
   * A promise
   */
  public buildAuthClientSecret(forcedInstance: string | null = null): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance(forcedInstance).pipe(take(1)).subscribe((instance) => {
          const currentInstance = (instance && instance.length) ? instance : this.environment.defaultInstance;
          let authClientSecret = '';
          const instanceParams = this.environment.getInstance(currentInstance);
          if (instanceParams) {
            authClientSecret = instanceParams[this.authObject].clientSecret;
          }
          resolve(authClientSecret);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  /**
   * Make use of getInstance to retrieve the instance and then use it to get the scope authentication parameter from the environment.
   *
   * @param forcedInstance
   * The instance forced to use
   *
   * @returns
   * A promise
   */
  public buildAuthScope(forcedInstance: string | null = null): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance(forcedInstance).pipe(take(1)).subscribe((instance) => {
          const currentInstance = (instance && instance.length) ? instance : this.environment.defaultInstance;
          let authScope = '';
          const instanceParams = this.environment.getInstance(currentInstance);
          if (instanceParams) {
            authScope = instanceParams[this.authObject].scope;
          }
          resolve(authScope);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  /**
   * Make use of getInstance to retrieve the instance and then use it to get the redirect url authentication parameter from the environment.
   *
   * @param forcedInstance
   * The instance forced to use
   *
   * @returns
   * A promise
   */
  public buildAuthRedirectUri(forcedInstance: string | null = null): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance(forcedInstance).pipe(take(1)).subscribe((instance) => {
          const currentInstance = (instance && instance.length) ? instance : this.environment.defaultInstance;
          let authRedirectUri = '';
          const instanceParams = this.environment.getInstance(currentInstance);
          if (instanceParams) {
            authRedirectUri = instanceParams[this.authObject].redirectUri;
          }
          resolve(authRedirectUri);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async isAuthenticated() {
    console.log('isAuthenticated function');
    try {
      console.log('isAuthenticated try');
      const { value } = await Preferences.get({ key: 'access_token' });
      console.log('isAuthenticated try2');
      if (value) {
        console.log('isAuthenticated value', value);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  public getLocalStorageToken() {
    return new Promise(resolve => {
      this.storageService.get(this.storagePrefix + 'access_token').then(accessToken => {
        if (accessToken !== null && accessToken !== '') {
          resolve(accessToken);
        }
        else {
          resolve(null);
        }
      });
    });
  }

  /**
   * Call the recover password functionality
   * The function takes an email string as an argument and returns an Observable of void.
   * Inside the function, it sets a timeout of 3 seconds and checks if the email is equal to "error@email.com".
   * If it is, the observer emits an error with a message "Email not found".
   * If the email is not equal to "error@email.com", the observer emits a next and complete event.
   *
   * @param email
   * The user email
   *
   * @returns
   * An observable
   */
  recoverEmailPassword(email: string, instance: string | null = null): Observable<void> {
    return new Observable<void>(observer => {
      (async () => {
        const authBaseUrl = await this.buildAuthBaseUrl(instance);
        const body = {
          data: {
            type: 'recover-password',
            attributes: {
              email
            }
          }
        };
        const options = {};
        this.httpClient.post(`${authBaseUrl}/v1/recover-password`, body, options).subscribe(
          {
            next: async (res: any) => {
              // console.log('res1', res);
              if (res && res.data) {
                observer.next();
                observer.complete();
              }
              else {
                // console.log('res2', res);
                observer.error({
                  message: ''
                });
              }
            },
            error: (error: any) => {
              observer.error({
                message: (error?.error?.error?.email) ?? 'Email not found'
              });
              // observer.next();
            }
          }
        );
      })()
        // HACK: prevent linter warning when `no-floating-promises` is set
        .then(null, observer.error);
    });
  }

  checkOtpCode(email: string, code: string, instance: string | null = null): Observable<void> {
    return new Observable<void>(observer => {
      (async () => {
        const authBaseUrl = await this.buildAuthBaseUrl(instance);
        const body = {
          data: {
            type: 'check-code',
            attributes: {
              email,
              code
            }
          }
        };
        const options = {};
        this.httpClient.post(`${authBaseUrl}/v1/check-code`, body, options).subscribe(
          {
            next: async (res: any) => {
              if (res && res.result) {
                observer.next();
                observer.complete();
              }
              else {
                observer.error({
                  message: ''
                });
              }
            },
            error: (error: any) => {
              observer.error({
                message: (error.error.error) ?? 'Invalid code'
              });
              // observer.next();
            }
          }
        );

      })()
        // HACK: prevent linter warning when `no-floating-promises` is set
        .then(null, observer.error);
    });
  }

  updatePassword(email: string, code: string, password: string, instance: string | null = null): Observable<void> {
    return new Observable<void>(observer => {
      (async () => {
        const authBaseUrl = await this.buildAuthBaseUrl(instance);
        const body = {
          data: {
            type: 'update-password',
            attributes: {
              email,
              code,
              password
            }
          }
        };
        const options = {};
        this.httpClient.post(`${authBaseUrl}/v1/update-password`, body, options).subscribe(
          {
            next: async (res: any) => {
              if (res && res.result) {
                observer.next();
                observer.complete();
              }
              else {
                observer.error({
                  message: ''
                });
              }
            },
            error: (error: any) => {
              console.log('error', error);
              observer.error({
                message: (error.error.error) ?? 'Invalid password'
              });
              // observer.next();
            }
          }
        );

      })()
        // HACK: prevent linter warning when `no-floating-promises` is set
        .then(null, observer.error);
    });
  }

  /**
   * Call the login functionality
   *
   * @param email
   * The user email
   *
   * @param password
   * The user password
   *
   * @param instance
   * The instance of the application
   *
   * @returns
   * An observable
   */
  login(email: string, password: string, instance: string | null = null): Observable<User> {
    return new Observable<User>(observer => {
      (async () => {
        const authSystem = await this.buildAuthSystem(instance);

        switch (authSystem) {
          case 'sanctum':
            this.loginSanctum(email, password, this.authDevice, instance).subscribe({
              next: (user: User) => {
                observer.next(user);
              },
              error: (error: any) => {
                observer.error(error);
              }
            });
            break;

          case 'passport':
            this.loginPassport(email, password, instance).subscribe({
              next: (user: User) => {
                observer.next(user);
              },
              error: (error: any) => {
                observer.error(error);
              }
            });
            break;

          case 'pkce':
            this.authorize(instance).subscribe({
              next: (user: User) => {
                observer.next(user);
              },
              error: (error: any) => {
                observer.error(error);
              }
            });
            break;

          default:
            observer.next(undefined);
        }
      })()
        // HACK: prevent linter warning when `no-floating-promises` is set
        .then(null, observer.error);
    });
  }

  loginSanctum(email: string, password: string, deviceName: string, instance: string | null = null): Observable<User> {
    return new Observable<User>(observer => {
      (async () => {
        const authBaseUrl = await this.buildAuthBaseUrl(instance);

        const body = {
          email,
          password,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          device_name: deviceName
        };
        const options = {};

        this.httpClient.post(`${authBaseUrl}/login`, body, options).subscribe(
          {
            next: async (res: any) => {
              if (res && res.data) {
                const user = new User();
                // user.billing = (res.data.billing ?? null);
                // user.createdAt = (res.data.createdAt ?? null);
                user.email = (res.data.email ?? null);
                // user.emailVerifiedAt = (res.data.emailVerifiedAt ?? null);
                // user.firstName = (res.data.firstName ?? null);
                user.id = (res.data.id ?? null);
                // user.lastName = (res.data.lastName ?? null);
                // user.phone = (res.data.phone ?? null);
                // user.status = (res.data.status ?? null);
                // user.subject = (res.data.subject ?? null);
                // user.typologyFrontend = (res.data.typologyFrontend ?? null);
                // user.updatedAt = (res.data.updatedAt ?? null);
                // user.username = (res.data.username ?? (user.email ?? null));

                const token = new Token();
                token.accessToken = res.access_token;
                token.expiresIn = null;
                token.refreshToken = null;
                token.tokenType = res.token_type;
                user.token = token;

                this.storageService.set(this.storagePrefix + 'access_token', token.accessToken);
                await Preferences.set({
                  key: 'access_token',
                  value: token.accessToken,
                });

                observer.next(user);
              }
              else {
                observer.next(undefined);
              }
            },
            error: (error: any) => {
              // console.log('error', error);
              observer.error({
                message: 'Your credentials are wrong. Please correct and try again'
              });
              // observer.next();
            }
          }
        );
      })()
        // HACK: prevent linter warning when `no-floating-promises` is set
        .then(null, observer.error);
    });

  }

  loginPassport(email: string, password: string, instance: string | null = null): Observable<User> {
    return new Observable<User>(observer => {
      (async () => {
        const authBaseUrl = await this.buildAuthBaseUrl(instance);
        const authClientId = await this.buildAuthClientId(instance);
        const authClientSecret = await this.buildAuthClientSecret(instance);
        const authScope = await this.buildAuthScope(instance);

        const body = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          grant_type: 'password',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          client_id: authClientId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          client_secret: authClientSecret,
          username: email,
          password,
          scope: authScope
        };
        const options = {};
        this.httpClient.post(`${authBaseUrl}/token`, body, options).subscribe(
          {
            next: (res: any) => {
              const user = new User();
              user.email = email;
              user.id = 'userId';
              const token = new Token();
              token.accessToken = res.access_token;
              token.expiresIn = res.expires_in;
              token.refreshToken = res.refresh_token;
              token.tokenType = res.token_type;
              user.token = token;
              observer.next(user);
            },
            error: (error: any) => {
              // console.log('error', error);
              observer.error({
                message: 'Your credentials are wrong. Please correct and try again'
              });
            }
          }
        );
      })()
        // HACK: prevent linter warning when `no-floating-promises` is set
        .then(null, observer.error);
    });
  }

  authorize(instance: string | null = null, prompt: string = 'none'): Observable<any> {
    return new Observable<any>(observer => {
      (async () => {
        const authBaseUrl = await this.buildAuthBaseUrl(instance);
        console.log('authBaseUrl', authBaseUrl);
        const authClientId = await this.buildAuthClientId(instance);
        const authRedirectUri = await this.buildAuthRedirectUri(instance);

        const responseType = 'code';
        const state = this.generateRandomString(64);
        const codeChallengeMethod = 'S256';
        const codeVerifier = this.generateRandomString(128);
        const codeChallenge = this.generateCodeChallenge(codeVerifier);
        // const prompt = 'login'; // "none", "consent", or "login"
        // eslint-disable-next-line max-len
        const queryParams = `?response_type=${responseType}&client_id=${authClientId}&redirect_uri=${authRedirectUri}&state=${state}&code_challenge_method=${codeChallengeMethod}&code_challenge=${codeChallenge}&prompt=${prompt}`;

        if (this.platform.is('capacitor')) {
          // Browser.addListener('browserFinished', async () => {
          //   console.log('browserFinished');
          //   await this.handleRedirectCallback();
          // });
          await Browser.open({ url: `${authBaseUrl}/authorize${queryParams}` });
          observer.next({ state, codeVerifier, codeChallenge });
        }
        else {
          window.location.href = `${authBaseUrl}/authorize${queryParams}`;
          observer.next({ state, codeVerifier, codeChallenge });
        }
      })()
        // HACK: prevent linter warning when `no-floating-promises` is set
        .then(null, observer.error);
    });
  }

  // handleRedirectCallback() {
  //   return new Promise<boolean>(async (resolve, reject) => {
  //     try {
  //       await Browser.removeAllListeners();
  //       // this.store.dispatch(LoadingActions.hide());

  //       const oauthCode = await this.storageService.get('oauth_code');
  //       const oauthState = await this.storageService.get('oauth_state');

  //       if (oauthCode && oauthState) {
  //         // TODO: Compare stored and returned state values
  //         // console.log(oauthCode, oauthState);
  //         await Utilities.waitFor(200);
  //         this.store.dispatch(LoginActions.convertCode({ code: oauthCode }));
  //       }
  //       else {
  //         this.store.dispatch(LoadingActions.hide());
  //         const error = new Error();
  //         error.message = 'Authorization fail';
  //         this.store.dispatch(LoadingActions.hide());
  //         await Utilities.waitFor(200);
  //         this.store.dispatch(LoginActions.authorizeFail({ error }));
  //       }

  //       resolve(true);
  //     } catch (error) {
  //       reject();
  //     }
  //   });
  // }

  handleRedirectCallback(queryString: any) {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        await Browser.removeAllListeners();
        // Create a URLSearchParams object to parse the query string
        const urlSearchParams = new URLSearchParams(queryString);

        // Get the value of "oauthCode" from the URLSearchParams
        const oauthCode = urlSearchParams.get('code');

        // Get the value of "state" from the URLSearchParams
        const oauthState = urlSearchParams.get('state');

        // const oauthCode = await this.storageService.get('oauth_code');
        // this.logger.log('oauthCode', oauthCode);
        // const oauthState = await this.storageService.get('oauth_state');
        // this.logger.log('oauthState', oauthState);

        if (oauthCode && oauthState) {
          await Utilities.waitFor(200);
          // this.logger.log('convertCode');
          this.store.dispatch(LoginActions.convertCode({ code: oauthCode }));
        }
        else {
          // this.logger.log('Authorization fail');
          this.store.dispatch(LoadingActions.hide());
          const error = new Error();
          error.message = 'Authorization fail';
          this.store.dispatch(LoadingActions.hide());
          await Utilities.waitFor(200);
          this.store.dispatch(LoginActions.authorizeFail({ error }));
        }

        resolve(true);
      } catch (error) {
        reject();
      }
    });
  }


  convertCode(code: string): Observable<User> {
    return new Observable<User>(observer => {
      (async () => {
        console.log('convertCode');
        this.store.select('login').pipe(
          take(1)
        ).subscribe(async (loginState) => {
          const authBaseUrl = await this.buildAuthBaseUrl(loginState.instance);
          const authClientId = await this.buildAuthClientId(loginState.instance);
          const authRedirectUri = await this.buildAuthRedirectUri(loginState.instance);
          const authCodeVerifier = loginState.pkceCodeVerifier;

          const body = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            grant_type: 'authorization_code',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            client_id: authClientId,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            redirect_uri: authRedirectUri,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            code_verifier: authCodeVerifier,
            code,
          };

          const options = {};
          this.httpClient.post(`${authBaseUrl}/token`, body, options).subscribe(
            {
              next: (res: any) => {
                const user = new User();
                // user.email = email;
                user.id = 'userId';
                const token = new Token();
                token.accessToken = res.access_token;
                token.expiresIn = res.expires_in;
                token.refreshToken = res.refresh_token;
                token.tokenType = res.token_type;
                user.token = token;
                observer.next(user);
              },
              error: (error: any) => {
                console.log('error', error);
                this.store.dispatch(LoadingActions.hide());
                observer.error({
                  message: 'Your code is wrong. Please try again'
                });
              }
            }
          );
        });
      })()
        // HACK: prevent linter warning when `no-floating-promises` is set
        .then(null, observer.error);
    });
  }

  /**
   * Call the refresh token functionality
   *
   * @param refreshToken
   * The refresh token
   *
   * @returns
   * An observable
   */
  refreshToken(refreshToken: string): Observable<User> {
    return new Observable<User>(observer => {
      (async () => {
        if (null === refreshToken) {
          // Sanctum and offline
          const user = new User();
          user.email = '';
          user.id = '';

          const { value } = await Preferences.get({ key: 'access_token' });

          // const accessToken = await this.storageService.get(this.storagePrefix + 'access_token');
          const token = new Token();
          token.accessToken = `${value}`;
          token.expiresIn = null;
          token.refreshToken = null;
          token.tokenType = 'Bearer';
          user.token = token;

          observer.next(user);
        }
        else {
          // Passport
          const authBaseUrl = await this.buildAuthBaseUrl();
          const authClientId = await this.buildAuthClientId();
          const authClientSecret = await this.buildAuthClientSecret();
          const authScope = await this.buildAuthScope();

          const body = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            grant_type: 'refresh_token',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            client_id: authClientId,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            client_secret: authClientSecret,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            refresh_token: refreshToken,
            scope: authScope
          };
          const options = {};
          this.httpClient.post(`${authBaseUrl}/token`, body, options).subscribe(
            {
              next: (res: any) => {
                const user = new User();
                user.email = 'userEmail';
                user.id = 'userId';
                const token = new Token();
                token.accessToken = res.access_token;
                token.expiresIn = res.expires_in;
                token.refreshToken = res.refresh_token;
                token.tokenType = res.token_type;
                user.token = token;
                observer.next(user);
              },
              error: (error: any) => {
                // console.log('error', error);
                // ToDo: Rimuovere i token
                observer.error({
                  message: 'Your credentials are wrong. Please correct and try again'
                });
                observer.next();
              }
            }
          );
        }
      })()
        // HACK: prevent linter warning when `no-floating-promises` is set
        .then(null, observer.error);
    });
  }

  /**
   * Call the logout functionality
   *
   * @returns
   * an observable
   */
  logout(): Observable<void> {
    return new Observable<void>(observer => {
      (async () => {
        const authBaseUrl = await this.buildAuthBaseUrl();
        const dataBaseUrl = await this.buildDataBaseUrl();
        // const authClientId = await this.buildAuthClientId();
        // const authClientSecret = await this.buildAuthClientSecret();
        // const authScope = await this.buildAuthScope();

        const serviceHeaders = await this.buildJsonApiHeaders();
        // const payload = new HttpParams();
        // const options = { headers: serviceHeaders, params: payload };

        const options = { headers: serviceHeaders };
        // const headers = new HttpHeaders({
        //   'Content-Type': 'application/json',
        //   'Authorization': 'Bearer ' + this.access_token
        // });
        // const options = { headers: headers };

        const body = {};

        try {
          this.httpClient.post(`${dataBaseUrl}/v1/logout`, body, options).subscribe(
            // this.httpClient.post(`${dataBaseUrl}/logout`, body, options).subscribe(
            // this.httpClient.post(`${authBaseUrl}/logout`, body, options).subscribe(
            {
              next: (res: any) => {
                // console.log('res', res);

                this.storageService.remove(this.storagePrefix + 'access_token');
                this.removeToken();

                // TODO
                setTimeout(() => {
                  observer.next();
                  observer.complete();
                }, 100);
              },
              error: (error: any) => {
                // console.log('error', error);
                if ((401 !== error.status) && (404 !== error.status)) {
                  observer.error({
                    message: 'Some error occurred'
                  });
                }
                this.storageService.remove(this.storagePrefix + 'access_token');
                this.removeToken();
                observer.next();
                observer.complete();
              }
            }
          );
        } catch (error) {
          console.log('###err', error);
        }

      })()
        // HACK: prevent linter warning when `no-floating-promises` is set
        .then(null, observer.error);
    });
  }

  /**
   * Call the logout functionality
   *
   * @returns
   * an observable
   */
  softLogout(): Observable<void> {
    return new Observable<void>(observer => {
      (async () => {
        this.store.select('login').pipe(take(1)).subscribe(async loginState => {
          // ToDo: authenticated user init
          this.storageService.remove(this.storagePrefix + 'access_token');
          const { value } = await Preferences.get({ key: 'access_token' });
          this.removeToken();

          // TODO
          try {
            setTimeout(() => {
              observer.next();
              observer.complete();
            }, 1000);
          } catch (error) {
            console.error('softLogout error', error);
          }
        });


      })()
        // HACK: prevent linter warning when `no-floating-promises` is set
        .then(null, observer.error);
    });
  }

  private removeToken = async () => {
    await Preferences.remove({ key: 'access_token' });
  };


  private generateRandomString(length: number): string {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = charset.length;
    for (let i = 0; i < length; i++) {
      result += charset.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  private generateCodeChallenge(codeVerifier: string) {
    return this.base64URL(CryptoJS.SHA256(codeVerifier));
  }

  private base64URL(hash: any) {
    return hash.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  }

  private getToken(): Observable<string> {
    return this.store.select('login').pipe(
      take(1),
      switchMap(loginState => {
        if (loginState.isLoggedIn && loginState.user && loginState.user.token && loginState.user.token.accessToken) {
          return of(loginState.user.token.accessToken);
        }
        return of('');
      })
    );
  }

  private buildJsonApiHeaders(): Promise<HttpHeaders> {
    return new Promise<HttpHeaders>((resolve, reject) => {
      try {
        this.getToken().pipe(take(1)).subscribe((token) => {
          const header = new HttpHeaders({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/vnd.api+json',
            // // eslint-disable-next-line @typescript-eslint/naming-convention
            // Accept: 'application/vnd.api+json',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: 'Bearer ' + token
          });
          resolve(header);
        });
      } catch (error) {
        // console.error('reject');
        reject(error);
      }
    });
  }
}
