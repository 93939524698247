import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Document } from '../../models/document/Document';
import * as DocumentsActions from './documents.actions';
import * as LoginActions from '../login/login.actions';

@Injectable()
export class DocumentsEffects {

  syncProjectDocumentsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentsActions.syncProjectDocumentsSuccess),
    map((payload: { documents: Document[] }) => DocumentsActions.upsertDocuments(payload))
  ));

  // Clear documents at logout
  logoutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LoginActions.logoutSuccess),
    map(() => DocumentsActions.clearDocuments())
  ));

  constructor(
    private actions$: Actions
  ) { }
}
