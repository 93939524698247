import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { User } from 'src/app/models/user/User';
import { RootState } from 'src/app/states/root.state';
import { UserService } from './user/user.service';
import { Project } from 'src/app/models/project/Project';
import { Task } from 'src/app/models/task/Task';
import { Document } from 'src/app/models/document/Document';
import { ProjectService } from './project/project.service';
import { Utilities } from '../../commons/utilities.class';
import { Queue } from '../../commons/queue.class';
import * as TasksActions from '../../states/tasks/tasks.actions';
import { selectTask, TasksState } from 'src/app/states/tasks/TasksState';
import { UtilityService } from './utility/utility.service';
import { Utility } from 'src/app/models/utility/Utility';
import { selectProject } from 'src/app/states/projects/ProjectsState';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  syncingTasksStateSubscription: Subscription;
  projectTasksQueue: Queue<{ projectId: string; changed: string }> = new Queue<{ projectId: string; changed: string }>();

  constructor(
    private store: Store<RootState>,
    private utilityService: UtilityService,
    private userService: UserService,
    private projectService: ProjectService
  ) {
  }

  /**
   *
   * @returns
   */
  setProjectsLastSync(): Observable<Utility> {
    return new Observable<Utility>((observer) => {
      try {
        const now = new Date();
        // eslint-disable-next-line max-len
        const value = now.toISOString().replace(/\-/g, '').replace(/T/g, '').replace(/\./g, '').replace(/\:/g, '').replace(/Z/g, '').slice(0, -3);
        const lastSync = new Utility();
        lastSync.id = 'projects_last_sync';
        lastSync.value = value;
        observer.next(lastSync);
        observer.complete();
      } catch (error) {
        observer.complete();
      }
    });
  }

  /**
   *
   * @param projectId
   * @returns
   */
  setProjectLastSync(projectId: string): Observable<Project> {
    return new Observable<Project>((observer) => {
      try {
        const now = new Date();
        // eslint-disable-next-line max-len
        const value = now.toISOString().replace(/\-/g, '').replace(/T/g, '').replace(/\./g, '').replace(/\:/g, '').replace(/Z/g, '').slice(0, -3);
        const project = new Project();
        project.id = projectId;
        project.lastSync = value;
        observer.next(project);
        observer.complete();
      } catch (error) {
        observer.complete();
      }
    });
  }

  /**
   * Call the get utilities functionality
   *
   * @returns
   * An observable
   */
  getUtilities(): Observable<Utility[]> {
    return new Observable<Utility[]>((observer) => {
      try {
        this.utilityService.getUtilities().then((call$) => {
          call$.subscribe(async (retrievedUtilities) => {
            const utilities: Utility[] = [];

            // Taxonomies
            const taxonomyKeys = [
              'document_status_list',
              'project_status_list',
              'revision_cause_list',
              'task_status_list'
            ];
            taxonomyKeys.forEach(taxonomyKey => {
              const taxonomy: Utility = new Utility();
              taxonomy.id = taxonomyKey;
              taxonomy.value = retrievedUtilities[taxonomyKey];
              utilities.push(taxonomy);
            });

            observer.next(utilities);
            observer.complete();
          },
            (error) => {
              observer.error(error);
              observer.complete();
            }
          );
        }).catch((error) => {
          observer.error(error);
          // observer.next();
          observer.complete();
        });
      } catch (error) {
        observer.error(error);
        observer.complete();
      }
    });
  }

  /**
   * Call the get user functionality
   *
   * @returns
   * An observable
   */
  getUser(): Observable<User> {
    return new Observable<User>((observer) => {
      try {
        this.userService.getUser().then((call$) => {
          call$.subscribe(async (retrievedUser) => {
            const user = new User();
            user.id = retrievedUser.id;
            user.email = retrievedUser.attributes.email;
            user.name = retrievedUser.attributes.name;
            user.phone = retrievedUser.attributes.phone;
            user.companyArea = retrievedUser.attributes.company_area;
            user.positionName = retrievedUser.attributes.position_name;
            user.companyName = retrievedUser.attributes.company_name;
            user.createdAt = retrievedUser.attributes.created_at;

            observer.next(user);
            observer.complete();
          });
        }).catch((error) => {
          observer.next();
          observer.complete();
        });
      } catch (error) {
        observer.next();
        observer.complete();
      }
    });
  }

  /**
   * Call the get projects functionality
   *
   * @returns
   * An observable
   */
  getProjects(changed: string = null): Observable<Project[]> {
    return new Observable<Project[]>((observer) => {
      try {
        this.store.select('login').pipe(take(1)).subscribe(async loginState => {
          // console.log('loginState.isLoggingOut', loginState.isLoggingOut);
          if (!loginState.isLoggingOut) {
            const filters: { key: string; value: any }[] = [{ key: 'with-trashed', value: true }];
            if (changed) {
              filters.push({ key: 'changed-after', value: changed });
            }
            const includes = [];
            const params = { filters, includes };
            this.projectService.getProjects(params).then((call$) => {
              // console.log('this.projectService.getProjects', call$);
              call$.subscribe(
                async (retrievedProjects) => {
                  // console.log('this.projectService.getProjects call$', retrievedProjects);
                  const projects = [];
                  await Utilities.asyncForEach(retrievedProjects, async (retrievedProject: any) => {
                    this.store.select(selectProject(retrievedProject.id)).pipe(take(1)).subscribe(
                      (storedProject) => {
                        let lastSync = null;
                        if (storedProject) {
                          lastSync = storedProject.lastSync;
                        }

                        const project = new Project();
                        project.id = retrievedProject.id;
                        project.name = retrievedProject.attributes.boat_name;
                        project.deliveryDate = retrievedProject.attributes.delivery_date;
                        project.tasks = [];
                        project.thumbnail = retrievedProject.attributes.cover;
                        project.status = retrievedProject.attributes.status;
                        project.lastSync = lastSync;
                        projects.push(project);

                        const projectId = project.id;
                        if ('Active' === project.status) {
                          const queueParams = { projectId, changed: lastSync };
                          this.projectTasksQueue.enqueue(queueParams);
                        }
                      },
                      (error) => {
                        console.log('projectService - getProjects - error 1', error);
                        observer.error(error);
                      });
                  });
                  this.store.dispatch(TasksActions.toSync());

                  this.syncingTasksStateSubscription = this.store.select('tasks').pipe(
                    filter(state => ((!state.isSynced) && (!state.isSyncing))),
                    take(1)
                  ).subscribe(tasksState => {
                    this.checkProjectTasksQueue(tasksState);
                  });

                  observer.next(projects);
                  observer.complete();
                },
                (error) => {
                  observer.error(error);
                  observer.complete();
                }
              );
            }).catch((error) => {
              observer.error(error);
              observer.complete();
            });
          }
          else {
            observer.complete();
          }
        });
      } catch (error) {
        observer.error(error);
        observer.complete();
      }
    });
  }

  /**
   *
   * @returns
   */
  getTasks(): Observable<Task[]> {
    console.log('getTasks');
    return new Observable<Task[]>((observer) => {
      try {
        this.projectService.getTasks().then((call$) => {
          call$.subscribe(async (retrievedProjectTasks) => {
            const tasks = [];

            await Utilities.asyncForEach(retrievedProjectTasks, async (retrievedProjectTask: any) => {
              const task = new Task();
              task.id = retrievedProjectTask.id;
              task.name = retrievedProjectTask.attributes.boat_name;
              tasks.push(task);
            });

            observer.next(tasks);
            observer.complete();
          });
        }).catch((error) => {
          observer.next();
          observer.complete();
        });
      } catch (error) {
        observer.complete();
      }
    });
  }

  /**
   *
   * @param projectId
   * @param changed
   * @returns
   */
  getProjectTasks(projectId: string, changed: string = null): Observable<{ tasks: Task[]; documents: Document[] }> {
    return new Observable<{ tasks: Task[]; documents: Document[] }>((observer) => {
      try {
        const filters: { key: string; value: any }[] = [{ key: 'with-trashed', value: true }];
        if (changed) {
          filters.push({ key: 'changed-after', value: changed });
        }
        const includes = ['visible_tasks', 'visible_tasks.included_documents'];
        // const includes = ['visible_tasks_with_parent_nodes', 'visible_tasks_with_parent_nodes.included_documents'];
        const params = { filters, includes };
        this.projectService.getProject(projectId, params).then((call$) => {
          call$.subscribe(async (retrievedProjectTasks) => {
            const tasks = [];
            const documents = [];

            await Utilities.asyncForEach(retrievedProjectTasks, async (retrievedProjectTask: any) => {
              switch (retrievedProjectTask.type) {
                case 'tasks':
                  this.store.select(selectTask(retrievedProjectTask.id)).pipe(take(1)).subscribe((storedTask) => {
                    const task = new Task();
                    task.id = retrievedProjectTask.id;
                    task.projectId = projectId;
                    task.projectName = retrievedProjectTask.attributes.project_name;
                    task.name = retrievedProjectTask.attributes.text;
                    task.type = retrievedProjectTask.attributes.type;
                    // Not updated documents preservation.
                    let storedTaskDocuments = [];
                    if (storedTask) {
                      storedTaskDocuments = storedTask.documents;
                    }
                    const combinedDocuments = storedTaskDocuments.concat(retrievedProjectTask.relationships.included_documents.data);
                    const uniqueDocuments = combinedDocuments.filter((obj, index, self) =>
                      index === self.findIndex((o) =>
                        o.id === obj.id && o.type === obj.type
                      )
                    );
                    task.documents = uniqueDocuments;

                    const taskLinks = [];
                    retrievedProjectTask.attributes.related_child_tasks.forEach(wrapperArray => {
                      wrapperArray.forEach(relatedChildTask => {
                        taskLinks.push({
                          id: relatedChildTask.id,
                          type: 'tasks'
                        });
                      });
                    });
                    task.links = taskLinks;

                    const taskStandardDocuments = [];
                    retrievedProjectTask.attributes.standard_documents.forEach(wrapperArray => {
                      wrapperArray.forEach(relatedChildTask => {
                        taskStandardDocuments.push({
                          id: relatedChildTask.id,
                          type: 'standardDocument',
                          internalDocId: relatedChildTask.internal_doc_id,
                          name: relatedChildTask.name,
                          revision: relatedChildTask.revision,
                          chapterCode: relatedChildTask.chapter_code,
                          fileLink: relatedChildTask.file_link,
                          smallFileLink: relatedChildTask.small_file_link,
                        });
                      });
                    });
                    task.standardDocuments = taskStandardDocuments;

                    task.status = retrievedProjectTask.attributes.status;
                    task.parent = retrievedProjectTask.attributes.parent;
                    task.internalDocId = retrievedProjectTask.attributes.internal_doc_id;

                    task.planningIssuerPosition = retrievedProjectTask.attributes.issuer_position;
                    task.planningIssueDeadlineDate = retrievedProjectTask.attributes.issue_deadline_date;
                    task.planningApprovalDeadlineDate = retrievedProjectTask.attributes.approval_deadline_date;
                    task.planningApproverPosition = retrievedProjectTask.attributes.approver_position;

                    task.visibilitySupplier = ('boolean' === typeof (retrievedProjectTask.attributes.supplier_visibility)) ?
                      (retrievedProjectTask.attributes.supplier_visibility) :
                      ('Yes' === (retrievedProjectTask.attributes.supplier_visibility));
                    task.visibilityOwner = ('boolean' === typeof (retrievedProjectTask.attributes.owner_team_visibility)) ?
                      (retrievedProjectTask.attributes.owner_team_visibility) :
                      ('Yes' === (retrievedProjectTask.attributes.owner_team_visibility));
                    task.visibilityCertifier = ('boolean' === typeof (retrievedProjectTask.attributes.cert_body_visibility)) ?
                      (retrievedProjectTask.attributes.cert_body_visibility) :
                      ('Yes' === (retrievedProjectTask.attributes.cert_body_visibility));

                    task.supplierCompanies = retrievedProjectTask.attributes.supplier_companies;
                    // eslint-disable-next-line max-len
                    task.supplierCompaniesString = (retrievedProjectTask.attributes.supplier_companies.map(company => company.name).join(', '));

                    task.certifierCompanies = retrievedProjectTask.attributes.cert_body_companies;
                    // eslint-disable-next-line max-len
                    task.certifierCompaniesString = (retrievedProjectTask.attributes.cert_body_companies.map(company => company.name).join(', '));

                    task.updatedAt = retrievedProjectTask.attributes.updated_at;
                    task.trashed = retrievedProjectTask.attributes.trashed || false;

                    tasks.push(task);
                  });
                  break;

                case 'documents':
                  const document = new Document();
                  document.id = retrievedProjectTask.id;

                  document.status = retrievedProjectTask.attributes.status;

                  document.approvalDate = retrievedProjectTask.attributes.approval_date;
                  document.approvalNotes = retrievedProjectTask.attributes.approval_notes;
                  document.approveAuthor = retrievedProjectTask.attributes.approve_author;
                  document.correctionsFileLink = retrievedProjectTask.attributes.corrections_file_link;
                  document.correctionsFileName = retrievedProjectTask.attributes.corrections_file_name;
                  document.fileLink = retrievedProjectTask.attributes.file_link;
                  document.issueAuthor = retrievedProjectTask.attributes.issue_author;
                  document.issueDate = retrievedProjectTask.attributes.issue_date;
                  document.revisionIndex = retrievedProjectTask.attributes.revision_index;
                  document.originRevisionIndex = retrievedProjectTask.attributes.origin_revision_index;
                  document.smallFileLink = retrievedProjectTask.attributes.small_file_link;
                  document.underRevisionReason = retrievedProjectTask.attributes.under_revision_reason;
                  document.updatedAt = retrievedProjectTask.attributes.updated_at;

                  documents.push(document);
                  break;
              }
            });

            observer.next({ tasks, documents });
            observer.complete();
          });
        }).catch((error) => {
          observer.next();
          observer.complete();
        });

        // const tasks = [];
        // observer.next(tasks);
        // observer.complete();

      } catch (error) {
        observer.complete();
      }
    });
  }

  /**
   *
   * @param projectId
   * @param changed
   * @returns
   */
  private async syncProjectTasks(projectId: string, changed: string = null) {
    return new Promise((resolve, reject) => {
      try {
        if (projectId) {
          this.store.dispatch(TasksActions.syncProjectTasks({ projectId, changed }));
          this.store.select('tasks').pipe(
            filter(state => !state.isSyncing),
            take(1)
          ).subscribe(() => {
            resolve(true);
          });
        }
        else {
          resolve(true);
        }
      } catch (error) {
        reject();
      }
    });
  }

  /**
   *
   * @param tasksState
   */
  private async checkProjectTasksQueue(tasksState: TasksState) {
    while (!this.projectTasksQueue.isEmpty()) {
      if (!tasksState.isSyncing) {
        const item = this.projectTasksQueue.dequeue();
        const projectId = item.projectId;
        const changed = item.changed;
        await this.syncProjectTasks(projectId, changed);
        await Utilities.waitFor(100);
      }
      else {
        console.log('waiting... projectTasksQueue');
        await Utilities.waitFor(1000);
      }
    }
    this.store.dispatch(TasksActions.synced());
  }
}
