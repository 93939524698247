/**
 * The Document model
 */
export class Document {
  /**
   * The id
   */
  id: string;
  /**
   * The status
   */
  status: string;

  approvalDate: string;
  approvalNotes: string;
  approveAuthor: string;
  correctionsFileLink: string;
  correctionsFileName: string;
  fileLink: string;
  issueAuthor: string;
  issueDate: string;
  revisionIndex: number;
  originRevisionIndex: string;
  smallFileLink: string;
  underRevisionReason: string;
  updatedAt: string;
}
