/**
 * The Utility model
 */
export class Utility {
  /**
   * The id
   */
  id: string;
  /**
   * The value
   */
  value: any;
}
