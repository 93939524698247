import { Action, createReducer, on } from '@ngrx/store';
import * as UtilitiesActions from './utilities.actions';
import { utilitiesAdapter, UtilitiesState } from './UtilitiesState';

/**
 * The initial state for the utilities
 */
const initialState: UtilitiesState = utilitiesAdapter.getInitialState({
  error: null,
  isSyncing: false,
  isSynced: false
});

/**
 * The reducer for the Utilities state
 */
const reducer = createReducer(
  initialState,
  on(UtilitiesActions.addUtility, (state, { utility }) => utilitiesAdapter.addOne(utility, state)),
  on(UtilitiesActions.setUtility, (state, { utility }) => utilitiesAdapter.setOne(utility, state)),
  on(UtilitiesActions.upsertUtility, (state, { utility }) => utilitiesAdapter.upsertOne(utility, state)),
  on(UtilitiesActions.addUtilities, (state, { utilities }) => utilitiesAdapter.addMany(utilities, state)),
  on(UtilitiesActions.upsertUtilities, (state, { utilities }) => utilitiesAdapter.upsertMany(utilities, state)),
  on(UtilitiesActions.updateUtility, (state, { update }) => utilitiesAdapter.updateOne(update, state)),
  on(UtilitiesActions.updateUtilities, (state, { updates }) => utilitiesAdapter.updateMany(updates, state)),
  on(UtilitiesActions.mapUtility, (state, { entityMap }) => utilitiesAdapter.mapOne(entityMap, state)),
  on(UtilitiesActions.mapUtilities, (state, { entityMap }) => utilitiesAdapter.map(entityMap, state)),
  on(UtilitiesActions.deleteUtility, (state, { id }) => utilitiesAdapter.removeOne(id, state)),
  on(UtilitiesActions.deleteUtilities, (state, { ids }) => utilitiesAdapter.removeMany(ids, state)),
  on(UtilitiesActions.deleteUtilitiesByPredicate, (state, { predicate }) => utilitiesAdapter.removeMany(predicate, state)),
  on(UtilitiesActions.loadUtilities, (state, { utilities }) => utilitiesAdapter.setAll(utilities, state)),
  on(UtilitiesActions.setUtilities, (state, { utilities }) => utilitiesAdapter.setMany(utilities, state)),
  on(UtilitiesActions.clearUtilities, state => utilitiesAdapter.removeAll({ ...state, selectedUserId: null })),

  on(UtilitiesActions.syncUtilities, (currentState) => ({
    ...currentState,
    error: null,
    isSynced: false,
    isSyncing: true
  })),
  on(UtilitiesActions.syncUtilitiesSuccess, (currentState, action) => ({
    ...currentState,
    isSynced: true,
    isSyncing: false
  })),
  on(UtilitiesActions.syncUtilitiesFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isSynced: false,
    isSyncing: false
  })),

);

/**
 * The utilities reducer
 */
export const utilitiesReducer = (state: UtilitiesState, action: Action) => reducer(state, action);
