import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { RootState } from 'src/app/states/root.state';
import { EnvironmentService } from '../../environment/environment.service';
import { JsonApiService } from '../jsonapi/jsonapi.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private store: Store<RootState>,
    private api: JsonApiService,
    private environment: EnvironmentService
  ) { }

  getInstance(): Observable<string> {
    return this.store.select('login').pipe(
      take(1),
      switchMap(loginState => {
        if (loginState.isLoggedIn && loginState.instance) {
          return of(loginState.instance);
        }
        return of('');
      })
    );
  }

  public buildApiBase() {
    return new Promise<string>((resolve, reject) => {
      try {
        this.getInstance().pipe(take(1)).subscribe((instance) => {
          const currentInstance = (instance && instance.length) ? instance : this.environment.defaultInstance;
          let apiBase = '';
          const instanceParams = this.environment.getInstance(currentInstance);
          if (instanceParams) {
            apiBase = instanceParams.data.baseUrl + '/' + instanceParams.data.apiVersion;
          }
          resolve(apiBase);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getProjects(params: { filters: { key: string; value: any }[]; includes: string[] } = null) {
    const apiBase = await this.buildApiBase();
    return new Observable<any>(observer => {
      const arrayParams = [];
      // Filters
      const stringFiltersParams: string = (params?.filters.length > 0) ?
        params?.filters.map(filter => `filter[${filter.key}]=${filter.value}`).join('&') :
        null;
      if (stringFiltersParams) {
        arrayParams.push(stringFiltersParams);
      }
      // Includes
      const stringIncludesParams: string = (params?.includes.length > 0) ?
        'include=' + `${params?.includes.join(',')}` :
        null;
      if (stringIncludesParams) {
        arrayParams.push(stringIncludesParams);
      }
      let stringParams = '';
      if (arrayParams.length > 0) {
        stringParams = '?' + arrayParams.join('&');
      }
      const route = `${apiBase}/projects${stringParams}`;
      this.api.customFullRouteGetRequest(route).then((call$) => {
        call$.pipe(take(1)).subscribe(
          (response: any) => {
            const projects = (response.data) ? response.data : null;
            observer.next(projects);
          },
          (error) => {
            // console.error('error', error);
            observer.error(error);
            observer.next();
          });
      }).catch(((error) => {
        observer.next();
      }));
    });
  }

  async getProject(projectId: string, params: { filters: { key: string; value: any }[]; includes: string[] } = null) {
    const apiBase = await this.buildApiBase();
    return new Observable<any>(observer => {
      const arrayParams = [];
      // Filters
      const stringFiltersParams: string = (params?.filters.length > 0) ?
        params?.filters.map(filter => `filter[${filter.key}]=${filter.value}`).join('&') :
        null;
      if (stringFiltersParams) {
        arrayParams.push(stringFiltersParams);
      }
      // Includes
      const stringIncludesParams: string = (params?.includes.length > 0) ?
        'include=' + `${params?.includes.join(',')}` :
        null;
      if (stringIncludesParams) {
        arrayParams.push(stringIncludesParams);
      }
      let stringParams = '';
      if (arrayParams.length > 0) {
        stringParams = '?' + arrayParams.join('&');
      }
      const route = `${apiBase}/projects/${projectId}${stringParams}`;
      this.api.customFullRouteGetRequest(route).then((call$) => {
        call$.pipe(take(1)).subscribe({
          next: (response: any) => {
            const tasks = (response.included) ? response.included : [];
            observer.next(tasks);
          },
          error: () => {
            observer.next();
          }
        });
      }).catch(((error) => {
        observer.next();
      }));
    });
  }

  async getTasks() {
    const apiBase = await this.buildApiBase();
    return new Observable<any>(observer => {
      const route = `${apiBase}/tasks`;
      this.api.customFullRouteGetRequest(route).then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const tasks = (response.data) ? response.data : null;
          observer.next(tasks);
        });
      }).catch(((error) => {
        observer.next();
      }));
    });
  }

}
