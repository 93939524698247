import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Project } from '../../models/project/Project';

/**
 * The interface of the projects state
 */
export interface ProjectsState extends EntityState<Project> {
  error: any;
  syncMessage: string;
  isSyncing: boolean;
  isSynced: boolean;
}

export const sortByName = (a: Project, b: Project): number => a?.name?.localeCompare(b?.name);

export const projectsAdapter: EntityAdapter<Project> = createEntityAdapter<Project>({
  sortComparer: sortByName,
});

export const getProjectsState = createFeatureSelector<ProjectsState>('projects');
// get the selectors
export const {
  selectIds: projectIds,
  selectEntities: projectEntities,
  selectAll: projects,
  selectTotal: totalProjects
} = projectsAdapter.getSelectors(getProjectsState);

// select the array of project ids
// export const selectProjectIds = createSelector(getProjectsState, (projects) => projects.ids);
export const selectProjectIds = projectIds;

// select the dictionary of project entities
// export const selectProjectEntities = createSelector(getProjectsState, (projects) => projects.entities);
export const selectProjectEntities = projectEntities;

// select the array of projects
// eslint-disable-next-line max-len
// export const selectAllProjects = createSelector(getProjectsState, (state) => (state?.ids as Array<string | number>)?.map(id => state?.entities[id]));
export const selectAllProjects = projects;

// select the total project count
export const selectProjectTotal = totalProjects;


// export const selectAllProjects = selectAll;
// eslint-disable-next-line max-len


export const selectProject = (id: string) => createSelector(
  selectProjectEntities,
  entities => entities[id]
);

export const selectProjectsByID = (ids: string[]) => createSelector(
  selectProjectEntities,
  entities => ids.map(id => entities[id])
);

export const selectAllActiveProjects = () => createSelector(
  selectAllProjects,
  (allProjects) => allProjects.filter(project => ('Active' === project.status)).filter(project => (!project.trashed))
);
