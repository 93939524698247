// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: {
    code: '0.0',
    build: '16'
  },
  title: 'DR&AM',
  enableDebugTools: false,
  logLevel: 0,
  usePKCE: true,
  isMultiInstance: true,
  defaultInstance: 'dream',
  instances: [
    {
      name: 'test',
      storage: {
        prefix: 'dream_mi_test_'
      },
      webAuth: {
        persistent: true,
        system: 'sanctum',
        baseUrl: 'https://test.staging.netseven.it/api',
        clientId: '',
        clientSecret: '',
        scope: '',
        redirectUri: ''
      },
      mobileAuth: {
        persistent: true,
        system: 'pkce',
        baseUrl: 'https://test.staging.netseven.it/oauth',
        clientId: '3',
        clientSecret: '',
        scope: '',
        redirectUri: 'it.netseven.dream://'
      },
      data: {
        baseUrl: 'https://test.staging.netseven.it/api',
        apiVersion: 'v1'
      }
    },
    {
      name: 'dev',
      storage: {
        prefix: 'DREAM_DEMO_DEV_PWA__'
      },
      webAuth: {
        persistent: true,
        system: 'pkce',
        baseUrl: 'https://dream.staging.netseven.it/oauth',
        clientId: '5',
        clientSecret: '',
        scope: '',
        redirectUri: 'https://mobile.dream.staging.netseven.it/success'
      },
      mobileAuth: {
        persistent: true,
        system: 'pkce',
        baseUrl: 'https://dream.staging.netseven.it/oauth',
        clientId: '3',
        clientSecret: '',
        scope: '',
        redirectUri: 'it.netseven.dream://'
      },
      data: {
        baseUrl: 'https://dream.staging.netseven.it/api',
        apiVersion: 'v1'
      }
    },
    {
      name: 'dream',
      storage: {
        prefix: 'DREAM_DEMO_PRD_PWA__'
      },
      webAuth: {
        persistent: true,
        system: 'pkce',
        baseUrl: 'https://dream.demo.staging.netseven.it/oauth',
        clientId: '4',
        clientSecret: '',
        scope: '',
        redirectUri: 'https://mobile.dream.demo.staging.netseven.it/success'
      },
      mobileAuth: {
        persistent: true,
        system: 'pkce',
        baseUrl: 'https://dream.demo.staging.netseven.it/oauth',
        clientId: '3',
        clientSecret: '',
        scope: '',
        redirectUri: 'it.netseven.dream://'
      },
      data: {
        baseUrl: 'https://dream.demo.staging.netseven.it/api',
        apiVersion: 'v1'
      }
    },
    {
      name: 'demo',
      storage: {
        prefix: 'DREAM_DEMO_PRD_PWA__'
      },
      webAuth: {
        persistent: true,
        system: 'pkce',
        baseUrl: 'https://dream.demo.staging.netseven.it/oauth',
        clientId: '4',
        clientSecret: '',
        scope: '',
        redirectUri: 'https://mobile.dream.demo.staging.netseven.it/success'
      },
      mobileAuth: {
        persistent: true,
        system: 'pkce',
        baseUrl: 'https://dream.demo.staging.netseven.it/oauth',
        clientId: '3',
        clientSecret: '',
        scope: '',
        redirectUri: 'it.netseven.dream://'
      },
      data: {
        baseUrl: 'https://dream.demo.staging.netseven.it/api',
        apiVersion: 'v1'
      }
    },
    {
      name: 'shipcollab',
      storage: {
        prefix: 'DREAM_SHIPCOLLAB_DEV_PWA__'
      },
      webAuth: {
        persistent: true,
        system: 'pkce',
        baseUrl: 'https://shipcollab.staging.netseven.it/oauth',
        clientId: '3',
        clientSecret: '',
        scope: '',
        redirectUri: 'https://mobile.shipcollab.staging.netseven.it/success'
      },
      mobileAuth: {
        persistent: true,
        system: 'pkce',
        baseUrl: 'https://shipcollab.staging.netseven.it/oauth',
        clientId: '3',
        clientSecret: '',
        scope: '',
        redirectUri: 'it.netseven.dream://'
      },
      data: {
        baseUrl: 'https://shipcollab.staging.netseven.it/api',
        apiVersion: 'v1'
      }
    },
    {
      name: 'tankoa-staging',
      storage: {
        prefix: 'DREAM_TANKOA_DEV_PWA__'
      },
      webAuth: {
        persistent: true,
        system: 'passport',
        // system: 'pkce',
        baseUrl: 'https://dream.tankoa.staging.netseven.it/oauth',
        clientId: '2',
        // clientId: '3',
        clientSecret: 'UMMknOMk61BuZHTnynLnbBbfTNxKejsaiwzrek6S',
        scope: '',
        redirectUri: ''
        // redirectUri: 'http://localhost:8100/success'
      },
      mobileAuth: {
        persistent: true,
        system: 'pkce',
        baseUrl: 'https://dream.tankoa.staging.netseven.it/oauth',
        clientId: '5',
        // clientId: '3',
        clientSecret: '',
        scope: '',
        redirectUri: 'it.netseven.dream://'
        // redirectUri: 'http://localhost:8100/success'
      },
      data: {
        baseUrl: 'https://dream.tankoa.staging.netseven.it/api',
        apiVersion: 'v1'
      }
    },
    {
      name: 'tankoa',
      storage: {
        prefix: 'DREAM_TANKOA_DEV_PWA__'
      },
      webAuth: {
        persistent: true,
        system: 'sanctum',
        baseUrl: 'https://dream.production.tankoa.it/api',
        clientId: '',
        clientSecret: '',
        scope: '',
        redirectUri: ''
      },
      mobileAuth: {
        persistent: true,
        system: 'sanctum',
        baseUrl: 'https://dream.production.tankoa.it/oauth',
        clientId: '3',
        clientSecret: '',
        scope: '',
        redirectUri: 'it.netseven.dream://'
      },
      data: {
        baseUrl: 'https://dream.production.tankoa.it/api',
        apiVersion: 'v1'
      }
    }
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
