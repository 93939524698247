import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { Task } from '../../models/task/Task';
import { DataService } from '../../services/data/data.service';
import * as TasksActions from './tasks.actions';
import * as DocumentsActions from '../documents/documents.actions';
import * as LoginActions from '../login/login.actions';

@Injectable()
export class TasksEffects {

  syncTasks$ = createEffect(() => this.actions$.pipe(
    ofType(TasksActions.syncTasks),
    switchMap(() => this.dataService.getTasks().pipe(
      map((tasks) => TasksActions.syncTasksSuccess({ tasks })),
      catchError(error => of(TasksActions.syncTasksFail({ error })))
    ))
  ));

  syncTasksSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TasksActions.syncTasksSuccess),
    map((payload: { tasks: Task[] }) => TasksActions.loadTasks(payload))
  ));

  syncProjectTasks$ = createEffect(() => this.actions$.pipe(
    ofType(TasksActions.syncProjectTasks),
    switchMap((
      payload: { projectId: string; changed: string }
    ) => this.dataService.getProjectTasks(payload.projectId, payload.changed).pipe(
      concatMap(({ tasks, documents }) => [
        TasksActions.syncProjectTasksSuccess({ tasks }),
        DocumentsActions.syncProjectDocumentsSuccess({ documents })
      ]),
      catchError(error => of(TasksActions.syncProjectTasksFail({ error })))
    ))
  ));

  syncProjectTasksSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TasksActions.syncProjectTasksSuccess),
    map((payload: { tasks: Task[] }) => TasksActions.upsertTasks(payload))
  ));

  // Clear tasks at logout
  logoutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LoginActions.logoutSuccess),
    map(() => TasksActions.clearTasks())
  ));

  // Clear tasks at soft logout
  softLogoutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LoginActions.softLogoutSuccess),
    map(() => TasksActions.clearTasks())
  ));

  constructor(
    private actions$: Actions,
    private dataService: DataService
  ) { }
}
