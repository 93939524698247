import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, timer } from 'rxjs';
import { debounce, switchMap, take } from 'rxjs/operators';
import { RootState } from 'src/app/states/root.state';
import { logout, refreshToken } from 'src/app/states/login/login.actions';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {

  constructor(
    private store: Store<RootState>
  ) { }

  canLoad(): Observable<boolean> {
    return this.store.select('login').pipe(
      debounce(() => timer(100)),
      take(1),
      switchMap(loginState => {
        // if (loginState?.isLoggedIn) {
        if (loginState.persistent) {
          if (loginState.user?.token?.refreshToken) {
            // console.log('has refresh token', loginState);
            const token = loginState.user.token.refreshToken;
            this.store.dispatch(refreshToken({ refreshToken: token }));
            return of(true);

            // this.store.select('network').pipe(
            //   take(1)
            // ).subscribe(networkState => {
            //   console.log('networkState', networkState);
            //   console.log('networkState connected', networkState.connected);
            //   if (networkState.connected) {
            //     const token = loginState.user.token.refreshToken;
            //     console.log('refresh token', token);
            //     this.store.dispatch(refreshToken({ refreshToken: token }));
            //     return of(true);
            //   }
            //   else {
            //     // this.store.dispatch(refreshToken({ refreshToken: null }));
            //     return of(true);
            //   }
            // });
          }
          else {
            // this.store.dispatch(refreshToken({ refreshToken: null }));
            return of(true);
          }
        }
        else {
          this.store.dispatch(logout());
          return of(true);
        }
        // }
        // else {
        //   console.log('anonymous');
        //   return of(true);
        // }
      })
    );
  }
}
