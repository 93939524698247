import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DataService } from 'src/app/services/data/data.service';
// eslint-disable-next-line max-len
import { login, loginFail, loginSuccess, logout, logoutFail, logoutSuccess, recoverPassword, recoverPasswordFail, recoverPasswordSuccess, refreshToken, refreshTokenFail, refreshTokenSuccess, updateUserSuccess, updateUserFail, checkCode, checkCodeFail, checkCodeSuccess, updatePassword, updatePasswordFail, updatePasswordSuccess, checkEmail, checkEmailFail, checkEmailSuccess, authorize, authorizeSuccess, authorizeFail, convertCode, convertCodeSuccess, convertCodeFail, softLogout, softLogoutSuccess, softLogoutFail } from './login.actions';

@Injectable()
export class LoginEffects {

  // recoverPassword$ = createEffect(() => this.actions$.pipe(
  //   ofType(recoverPassword),
  //   switchMap((payload: {
  //     email: string; instance: string;
  //   }) => this.authService.recoverEmailPassword(payload.email, payload.instance).pipe(
  //     map(() => recoverPasswordSuccess()),
  //     catchError(error => of(recoverPasswordFail({ error })))
  //   ))
  // ));

  recoverPassword$ = createEffect(() => this.actions$.pipe(
    ofType(recoverPassword),
    map(() => recoverPasswordSuccess()),
    catchError(error => of(recoverPasswordFail({ error })))
  ));

  // recoverPasswordSuccess$ = createEffect(() => this.actions$.pipe(
  //   ofType(recoverPasswordSuccess),
  //   map(() => recoverPasswordFail(null)),
  //   catchError(error => of(recoverPasswordFail({ error })))
  // ));

  checkEmail$ = createEffect(() => this.actions$.pipe(
    ofType(checkEmail),
    switchMap((payload: {
      email: string; instance: string;
    }) => this.authService.recoverEmailPassword(payload.email, payload.instance).pipe(
      map(() => checkEmailSuccess()),
      catchError(error => of(checkEmailFail({ error })))
    ))
  ));

  // checkEmailSuccess$ = createEffect(() => this.actions$.pipe(
  //   ofType(checkEmailSuccess),
  //   map(() => checkEmailFail(null)),
  //   catchError(error => of(checkEmailFail({ error })))
  // ));

  checkCode$ = createEffect(() => this.actions$.pipe(
    ofType(checkCode),
    switchMap((payload: {
      email: string; code: string; instance: string;
    }) => this.authService.checkOtpCode(payload.email, payload.code, payload.instance).pipe(
      map(() => checkCodeSuccess()),
      catchError(error => of(checkCodeFail({ error })))
    ))
  ));

  // checkCodeSuccess$ = createEffect(() => this.actions$.pipe(
  //   ofType(checkCodeSuccess),
  //   map(() => checkCodeFail(null)),
  //   catchError(error => of(checkCodeFail({ error })))
  // ));

  updatePassword$ = createEffect(() => this.actions$.pipe(
    ofType(updatePassword),
    switchMap((payload: {
      email: string; code: string; password: string; instance: string;
    }) => this.authService.updatePassword(payload.email, payload.code, payload.password, payload.instance).pipe(
      map(() => updatePasswordSuccess()),
      catchError(error => of(updatePasswordFail({ error })))
    ))
  ));

  updatePasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(updatePasswordSuccess),
    map(() => updatePasswordFail(null)),
    catchError(error => of(updatePasswordFail({ error })))
  ));

  authorize$ = createEffect(() => this.actions$.pipe(
    ofType(authorize),
    switchMap((payload: {
      instance: string;
      prompt: string;
    }) => this.authService.authorize(payload.instance, payload.prompt).pipe(
      map((data) => authorizeSuccess({ state: data.state, codeVerifier: data.codeVerifier, codeChallenge: data.codeChallenge })),
      catchError(error => of(authorizeFail({ error })))
    ))
  ));

  login$ = createEffect(() => this.actions$.pipe(
    ofType(login),
    switchMap((payload: {
      email: string; password: string; persistent: boolean; instance: string;
    }) => this.authService.login(payload.email, payload.password, payload.instance).pipe(
      map((user) => loginSuccess({ user })),
      catchError(error => of(loginFail({ error })))
    ))
  ));

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loginSuccess),
    switchMap(() => this.dataService.getUser().pipe(
      map((user) => updateUserSuccess({ user })),
      catchError(error => of(updateUserFail({ error })))
    ))
  ));

  convertCode$ = createEffect(() => this.actions$.pipe(
    ofType(convertCode),
    switchMap((payload: {
      code: string;
    }) => this.authService.convertCode(payload.code).pipe(
      map((user) => convertCodeSuccess({ user })),
      catchError(error => of(convertCodeFail({ error })))
    ))
  ));

  convertCodeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(convertCodeSuccess),
    switchMap(() => this.dataService.getUser().pipe(
      map((user) => updateUserSuccess({ user })),
      catchError(error => of(updateUserFail({ error })))
    ))
  ));

  refreshToken$ = createEffect(() => this.actions$.pipe(
    ofType(refreshToken),
    switchMap((payload: { refreshToken: string }) => this.authService.refreshToken(payload.refreshToken).pipe(
      map((user) => refreshTokenSuccess({ user })),
      catchError(error => of(refreshTokenFail({ error })))
    ))
  ));

  refreshTokenSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(refreshTokenSuccess),
    switchMap(() => this.dataService.getUser().pipe(
      map((user) => updateUserSuccess({ user })),
      catchError(error => of(updateUserFail({ error })))
    ))
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(logout),
    switchMap(() => this.authService.logout().pipe(
      map((user) => logoutSuccess()),
      catchError(error => of(logoutFail({ error })))
    ))
  ));

  softLogout$ = createEffect(() => this.actions$.pipe(
    ofType(softLogout),
    switchMap(() => this.authService.softLogout().pipe(
      map(user => softLogoutSuccess()),
      catchError(error => of(softLogoutFail({ error })))
    ))
  ));

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private dataService: DataService
  ) { }
}
