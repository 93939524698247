import { Task } from '../task/Task';

/**
 * The Project model
 */
export class Project {
  /**
   * The id
   */
  id: string;
  /**
   * The name
   */
  name: string;
  /**
   * The project's tasks
   */
  tasks?: Task[];

  deliveryDate: string;

  thumbnail: string;

  status: string;
  trashed: boolean;
  lastSync: string;
}
