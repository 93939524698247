import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, timer } from 'rxjs';
import { debounce, switchMap, take } from 'rxjs/operators';
// import { AuthService } from 'src/app/services/auth/auth.service';
import { logout, softLogout } from 'src/app/states/login/login.actions';
import { RootState } from 'src/app/states/root.state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private store: Store<RootState>,
    private router: Router,
    // private authService: AuthService
  ) { }

  canLoad(): Observable<boolean> {
    return this.store.select('login').pipe(
      debounce(() => timer(100)),
      take(1),
      switchMap(loginState => {
        if (loginState.isLoggedIn) {
          return of(true);
        }
        else {
          this.store.dispatch(softLogout());
          this.router.navigate(['login']);
          return of(false);

          // this.authService.isAuthenticated().then((isAuthenticated) => {
          //   console.log('isAuthenticated', isAuthenticated);
          //   if (isAuthenticated) {
          //     console.log('isAuthenticated');
          //     return of(true);
          //   }
          //   console.log('logout');
          //   // this.store.dispatch(logout());
          //   this.router.navigateByUrl('login');
          //   return of(false);
          // }).catch(() => {
          //   console.log('logout 2');
          //   // this.store.dispatch(logout());
          //   this.router.navigateByUrl('login');
          //   return of(false);
          // });
        }
      })
    );
  }
}
