import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Utility } from '../../models/utility/Utility';

/**
 * The interface of the tasks state
 */
export interface UtilitiesState extends EntityState<Utility> {
  error: any;
  isSyncing: boolean;
  isSynced: boolean;
}

export const selectUtilityId = ((a: Utility): string => a.id);

export const utilitiesAdapter: EntityAdapter<Utility> = createEntityAdapter<Utility>({
  selectId: selectUtilityId
});

export const getUtilitiesState = createFeatureSelector<UtilitiesState>('utilities');
export const {
  selectIds: utilityIds,
  selectEntities: utilityEntities,
  selectAll: utilities,
  selectTotal: totalUtilities
} = utilitiesAdapter.getSelectors(getUtilitiesState);

// select the array of utility ids
export const selectUtilityIds = utilityIds;

// select the dictionary of utility entities
export const selectUtilityEntities = utilityEntities;

// select the array of utilities
export const selectAllUtilities = utilities;

// select the total utility count
export const selectUtilityTotal = totalUtilities;

export const selectUtility = (id: string) => createSelector(
  utilityEntities,
  entities => entities[id]
);

export const selectUtilitiesByID = (ids: string[]) => createSelector(
  utilityEntities,
  entities => ids.map(id => entities[id])
);

