import { Action, createReducer, on } from '@ngrx/store';
import { rootInitialState } from '../root.initial.state';
import { NetworkState } from './network.state';
import * as NetworkActions from './network.actions';

/**
 * The initial state for the network
 */
const initialState: NetworkState = rootInitialState.network;

/**
 * The reducer for the network state
 */
const reducer = createReducer(
  initialState,
  on(NetworkActions.connectionChange, (currentState, action) => ({
    ...currentState,
    connected: ((('wifi' === action.connectionType) || ('cellular' === action.connectionType)) ? true : false),
    connectionType: action.connectionType
  }))
);

/**
 * The network reducer
 */
export const networkReducer = (state: NetworkState, action: Action) => reducer(state, action);
