import { Action, createReducer, on } from '@ngrx/store';
// eslint-disable-next-line max-len
import { projectsAdapter, ProjectsState } from './ProjectsState';
import * as ProjectsActions from './projects.actions';
/**
 * The initial state for the projects
 */
const initialState: ProjectsState = projectsAdapter.getInitialState({
  error: null,
  syncMessage: '',
  isSyncing: false,
  isSynced: false
});

/**
 * The reducer for the projects state
 */
const reducer = createReducer(
  initialState,
  on(ProjectsActions.addProject, (state, { project }) => projectsAdapter.addOne(project, state)),
  on(ProjectsActions.setProject, (state, { project }) => projectsAdapter.setOne(project, state)),
  on(ProjectsActions.upsertProject, (state, { project }) => projectsAdapter.upsertOne(project, state)),
  on(ProjectsActions.addProjects, (state, { projects }) => projectsAdapter.addMany(projects, state)),
  on(ProjectsActions.upsertProjects, (state, { projects }) => projectsAdapter.upsertMany(projects, state)),
  on(ProjectsActions.updateProject, (state, { update }) => projectsAdapter.updateOne(update, state)),
  on(ProjectsActions.updateProjects, (state, { updates }) => projectsAdapter.updateMany(updates, state)),
  on(ProjectsActions.mapProject, (state, { entityMap }) => projectsAdapter.mapOne(entityMap, state)),
  on(ProjectsActions.mapProjects, (state, { entityMap }) => projectsAdapter.map(entityMap, state)),
  on(ProjectsActions.deleteProject, (state, { id }) => projectsAdapter.removeOne(id, state)),
  on(ProjectsActions.deleteProjects, (state, { ids }) => projectsAdapter.removeMany(ids, state)),
  on(ProjectsActions.deleteProjectsByPredicate, (state, { predicate }) => projectsAdapter.removeMany(predicate, state)),
  on(ProjectsActions.loadProjects, (state, { projects }) => projectsAdapter.setAll(projects, state)),
  on(ProjectsActions.setProjects, (state, { projects }) => projectsAdapter.setMany(projects, state)),
  on(ProjectsActions.clearProjects, state => projectsAdapter.removeAll({ ...state, selectedUserId: null })),

  on(ProjectsActions.syncProjects, (currentState) => ({
    ...currentState,
    error: null,
    isSynced: false,
    isSyncing: true
  })),
  on(ProjectsActions.syncProjectsSuccess, (currentState, action) => ({
    ...currentState,
    isSynced: true,
    isSyncing: false
  })),
  on(ProjectsActions.syncProjectsFail, (currentState, action) => ({
    ...currentState,
    error: action.error,
    isSynced: false,
    isSyncing: false
  })),
);

/**
 * The projects reducer
 */
export const projectsReducer = (state: ProjectsState, action: Action) => reducer(state, action);
