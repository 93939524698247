import { Predicate } from '@angular/core';
import { Update, EntityMapOne, EntityMap } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Document } from '../../models/document/Document';

export const loadDocuments = createAction('[Documents/Entity] Load Documents', props<{ documents: Document[] }>());
export const setDocuments = createAction('[Documents/Entity] Set Documents', props<{ documents: Document[] }>());
export const addDocument = createAction('[Documents/Entity] Add Document', props<{ document: Document }>());
export const setDocument = createAction('[Documents/Entity] Set Document', props<{ document: Document }>());
export const upsertDocument = createAction('[Documents/Entity] Upsert Document', props<{ document: Document }>());
export const addDocuments = createAction('[Documents/Entity] Add Documents', props<{ documents: Document[] }>());
export const upsertDocuments = createAction('[Documents/Entity] Upsert Documents', props<{ documents: Document[] }>());
export const updateDocument = createAction('[Documents/Entity] Update Document', props<{ update: Update<Document> }>());
export const updateDocuments = createAction('[Documents/Entity] Update Documents', props<{ updates: Update<Document>[] }>());
export const mapDocument = createAction('[Documents/Entity] Map Document', props<{ entityMap: EntityMapOne<Document> }>());
export const mapDocuments = createAction('[Documents/Entity] Map Documents', props<{ entityMap: EntityMap<Document> }>());
export const deleteDocument = createAction('[Documents/Entity] Delete Document', props<{ id: string }>());
export const deleteDocuments = createAction('[Documents/Entity] Delete Documents', props<{ ids: string[] }>());
// eslint-disable-next-line max-len
export const deleteDocumentsByPredicate = createAction('[Documents/Entity] Delete Documents By Predicate', props<{ predicate: Predicate<Document> }>());
export const clearDocuments = createAction('[Documents/Entity] Clear Documents');


/**
 * The sync action
 */
export const syncDocuments = createAction('[Documents/API] Sync Documents');

/**
 * The syncDocuments success action
 */
export const syncDocumentsSuccess = createAction('[Documents/API] Sync Documents success', props<{ documents: Document[] }>());

/**
 * The syncDocuments fail action
 */
export const syncDocumentsFail = createAction('[Documents/API] Sync Documents fail', props<{ error: any }>());

/**
 * The sync project Documents action
 */
export const syncProjectDocuments = createAction('[Documents/API] Sync project Documents', props<{ projectId: string }>());

/**
 * The sync Project Documents success action
 */
// eslint-disable-next-line max-len
export const syncProjectDocumentsSuccess = createAction('[Documents/API] Sync project Documents success', props<{ documents: Document[] }>());

/**
 * The sync Project Documents fail action
 */
export const syncProjectDocumentsFail = createAction('[Documents/API] Sync project Documents fail', props<{ error: any }>());
