import { Component, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SplashScreen } from '@capacitor/splash-screen';
import { Browser } from '@capacitor/browser';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from './services/environment/environment.service';
import { NetworkService } from './services/network/network.service';
import { StorageService } from './services/storage/storage.service';
import { App } from '@capacitor/app';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private environment: EnvironmentService,
    private networkService: NetworkService,
    private storageService: StorageService,
    private authService: AuthService,
    private titleService: Title,
    private zone: NgZone
  ) {
    this.initializeApp();
  }

  /**
   * App initialization
   */
  async initializeApp() {
    this.platform.ready().then(async () => {
      this.networkService.initializeNetwork();

      this.translate.setDefaultLang('en');
      this.translate.use('en');

      this.setTitle(this.environment.title);

      this.initializeDeepLink();

      await SplashScreen.hide();
    });
  }

  /**
   *
   * @param newTitle
   */
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  private initializeDeepLink() {
    App.addListener('appUrlOpen', data => {
      console.log('App opened with URL:', data);
      this.zone.run(async () => {

        // Get the query string from the URL
        const queryString = data.url.split('it.netseven.dream://')[1];
        console.log('queryString:', queryString);

        if (queryString) {
          console.log('handleRedirectCallback');
          await this.handleRedirectCallback(queryString);
          console.log('authService.handleRedirectCallback');
          await this.authService.handleRedirectCallback(queryString);
          if (this.platform.is('ios')) {
            console.log('Browser.close');
            await Browser.close();
          }
        }
        else {
          if (this.platform.is('ios')) {
            console.log('Browser.close');
            await Browser.close();
          }
        }
      });
    });
  }

  private handleRedirectCallback(queryString: any) {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        // Create a URLSearchParams object to parse the query string
        const urlSearchParams = new URLSearchParams(queryString);

        // Get the value of "code" from the URLSearchParams
        const codeValue = urlSearchParams.get('code');
        if (codeValue) {
          // localStorage.setItem('oauth_code', codeValue);
          await this.storageService.set('oauth_code', codeValue);
        }

        // Get the value of "state" from the URLSearchParams
        const stateValue = urlSearchParams.get('state');
        if (stateValue) {
          // localStorage.setItem('oauth_state', stateValue);
          await this.storageService.set('oauth_state', stateValue);
        }

        resolve(true);
      } catch (error) {
        reject();
      }
    });
  }
}
