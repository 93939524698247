/**
 * The Token model
 */
export class Token {
  /**
   * The access token
   */
  accessToken = '';
  /**
   * The expiration time
   */
  expiresIn: number | null = 0;
  /**
   * The refresh token
   */
  refreshToken: string | null = '';
  /**
   * The token type
   */
  tokenType = '';
}
