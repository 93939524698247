import { Action, createReducer, on } from '@ngrx/store';
import { rootInitialState } from '../root.initial.state';
import { LoadingState } from './loading.state';
import * as LoadingActions from './loading.actions';

/**
 * The initial state for the loading
 */
const initialState: LoadingState = rootInitialState.loading;

/**
 * The reducer for the loading state
 */
const reducer = createReducer(
  initialState,
  on(LoadingActions.show, (currentState, action) => ({
    show: true,
    context: action.context
  })),
  on(LoadingActions.hide, () => ({
    show: false,
    context: {
      title: '',
      description: '',
    }
  }))
);

/**
 * The loading reducer
 */
export const loadingReducer = (state: LoadingState, action: Action) => reducer(state, action);
