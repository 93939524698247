import { Predicate } from '@angular/core';
import { Update, EntityMapOne, EntityMap } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { LocalFile } from '../../models/local-file/LocalFile';

export const loadLocalFiles = createAction('[LocalFiles/Entity] Load Local Files', props<{ localFiles: LocalFile[] }>());
export const setLocalFiles = createAction('[LocalFiles/Entity] Set Local Files', props<{ localFiles: LocalFile[] }>());
export const addLocalFile = createAction('[LocalFiles/Entity] Add Local File', props<{ localFile: LocalFile }>());
export const setLocalFile = createAction('[LocalFiles/Entity] Set Local File', props<{ localFile: LocalFile }>());
export const upsertLocalFile = createAction('[LocalFiles/Entity] Upsert Local File', props<{ localFile: LocalFile }>());
export const addLocalFiles = createAction('[LocalFiles/Entity] Add Local Files', props<{ localFiles: LocalFile[] }>());
export const upsertLocalFiles = createAction('[LocalFiles/Entity] Upsert Local Files', props<{ localFiles: LocalFile[] }>());
export const updateLocalFile = createAction('[LocalFiles/Entity] Update Local File', props<{ update: Update<LocalFile> }>());
export const updateLocalFiles = createAction('[LocalFiles/Entity] Update Local Files', props<{ updates: Update<LocalFile>[] }>());
export const mapLocalFile = createAction('[LocalFiles/Entity] Map Local File', props<{ entityMap: EntityMapOne<LocalFile> }>());
export const mapLocalFiles = createAction('[LocalFiles/Entity] Map Local Files', props<{ entityMap: EntityMap<LocalFile> }>());
export const deleteLocalFile = createAction('[LocalFiles/Entity] Delete Local File', props<{ id: string }>());
export const deleteLocalFiles = createAction('[LocalFiles/Entity] Delete Local Files', props<{ ids: string[] }>());
// eslint-disable-next-line max-len
export const deleteLocalFilesByPredicate = createAction('[LocalFiles/Entity] Delete Local Files By Predicate', props<{ predicate: Predicate<LocalFile> }>());
export const clearLocalFiles = createAction('[LocalFiles/Entity] Clear Local Files');
