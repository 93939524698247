import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth-guard';
import { LoginGuard } from './guards/login/login-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'loader',
    pathMatch: 'full'
  },
  {
    path: 'loader',
    loadChildren: () => import('./pages/loader/loader.module').then(m => m.LoaderPageModule)
  },
  {
    path: 'recover-password',
    loadChildren: () => import('./pages/recover-password/recover-password.module').then(m => m.RecoverPasswordPageModule)
  },
  {
    path: 'check-code',
    loadChildren: () => import('./pages/check-code/check-code.module').then(m => m.CheckCodePageModule)
  },
  {
    path: 'update-password',
    loadChildren: () => import('./pages/update-password/update-password.module').then(m => m.UpdatePasswordPageModule)
  },
  {
    path: 'auth',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canLoad: [LoginGuard]
  },
  {
    path: 'success',
    redirectTo: 'pkce',
    pathMatch: 'full',
  },
  {
    path: 'pkce',
    loadComponent: () => import('./pages/auth/pkce/pkce.page').then(m => m.PkcePage)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then(m => m.LogoutPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'project/:projectId',
    loadChildren: () => import('./pages/project/project.module').then(m => m.ProjectPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'project/:projectId/milestone/:milestoneId',
    loadChildren: () => import('./pages/milestone/milestone.module').then(m => m.MilestonePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'project/:projectId/task/:taskId',
    // loadChildren: () => import('./pages/task/task-routing.module').then((m) => m.TaskPageRoutingModule),
    loadChildren: () => import('./pages/task/task.module').then(m => m.TaskPageModule),
    canLoad: [AuthGuard]
  },
  // {
  //   path: 'project/:projectId/task/:taskId/link',
  //   loadChildren: () => import('./pages/task-link/task-link.module').then(m => m.TaskLinkPageModule),
  //   canLoad: [AuthGuard]
  // },
  {
    path: 'project/:projectId/task/:taskId/version/:taskDocumentIndex',
    loadChildren: () => import('./pages/task/task.module').then(m => m.TaskPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sync-contents',
    loadChildren: () => import('./pages/sync-contents/sync-contents.module').then(m => m.SyncContentsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'guides',
    loadChildren: () => import('./pages/guides/guides.module').then(m => m.GuidesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'info',
    loadChildren: () => import('./pages/info/info.module').then(m => m.InfoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
    canLoad: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
