import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Utility } from 'src/app/models/utility/Utility';
import { DataService } from '../../services/data/data.service';
import * as UtilitiesActions from './utilities.actions';
import * as ProjectsActions from '../projects/projects.actions';
import * as LoginActions from '../login/login.actions';

@Injectable()
export class UtilitiesEffects {

  syncUtilities$ = createEffect(() => this.actions$.pipe(
    ofType(UtilitiesActions.syncUtilities),
    switchMap(() => this.dataService.getUtilities().pipe(
      map((utilities) => UtilitiesActions.syncUtilitiesSuccess({ utilities })),
      catchError(error => of(UtilitiesActions.syncUtilitiesFail({ error })))
    ))
  ));

  syncUtilitiesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(UtilitiesActions.syncUtilitiesSuccess),
    map((payload: { utilities: Utility[] }) => UtilitiesActions.upsertUtilities(payload))
  ));

  syncUtilitiesFail$ = createEffect(() => this.actions$.pipe(
    ofType(UtilitiesActions.syncUtilitiesFail),
    map(() => LoginActions.softLogout())
  ));

  syncProjectsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectsActions.syncProjectsSuccess),
    switchMap(() => this.dataService.setProjectsLastSync().pipe(
      map((utility) => UtilitiesActions.upsertUtility({ utility }))
    ))
  ));

  syncProjectsFail$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectsActions.syncProjectsFail),
    map(() => LoginActions.softLogout())
  ));

  // Delete utility "projects_last_sync" at login
  login$ = createEffect(() => this.actions$.pipe(
    ofType(LoginActions.login),
    map(() => UtilitiesActions.deleteUtility({ id: 'projects_last_sync' }))
  ));

  // Delete utility "projects_last_sync" at logoutSuccess
  logoutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LoginActions.logoutSuccess),
    map(() => UtilitiesActions.deleteUtility({ id: 'projects_last_sync' }))
  ));

  // Delete utility "projects_last_sync" at softLogoutSuccess
  softLogoutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LoginActions.softLogoutSuccess),
    map(() => UtilitiesActions.deleteUtility({ id: 'projects_last_sync' }))
  ));

  // Clear utilities at logout
  // logoutSuccess$ = createEffect(() => this.actions$.pipe(
  //   ofType(LoginActions.logoutSuccess),
  //   map(() => UtilitiesActions.clearUtilities())
  // ));

  constructor(
    private actions$: Actions,
    private dataService: DataService
  ) { }
}
